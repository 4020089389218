import store from '../store'
import { qs, qsa } from '../utils'
import gsap from 'gsap'

export default class Menu {
  constructor(obj = {}) {
    const desktopMenu = obj.desktopMenu
    const mobileMenu = obj.mobileMenu
    const footer = obj.footer
    const url = obj.url
    const overlay = qs('.overlay')
    const background = qs('.background')
    const container = qs('.menu-list', desktopMenu)
    const ogl = store.BG ? store.BG.ogl : null
    const uniforms = ogl ? ogl.data.program.uniforms : null

    this.dom = {
      overlay,
      container,
      background,
      open: obj.open,
      close: obj.close,
      desktop: {
        el: desktopMenu,
        links: desktopMenu ? qsa('a', desktopMenu) : null,
      },
      mobile: {
        el: mobileMenu,
        links: mobileMenu ? qsa('a', mobileMenu) : null,
      },
      footer: {
        links: footer ? qsa('a', footer) : null,
      },
      url: url,
    }

    this.state = {
      open: false,
      active: false,
      hover: false,
      position: null,
      uniforms,
    }

    this.init()
  }

  on() {
    const { sniff } = store
    const { open, close, desktop, container, footer } = this.dom
    open && open.addEventListener('click', this.openMenu)
    close && close.addEventListener('click', this.closeMenu)

    if (!sniff.browser.isDevice) {
      desktop.links.forEach((link) => {
        link.addEventListener('mouseenter', this.showLink)
        link.addEventListener('mouseleave', this.hideLink)
        link.addEventListener('click', this.fixMenu)
      })

      container.addEventListener('mouseenter', this.showBg)
      container.addEventListener('mouseleave', this.hideBg)
    }
  }

  off() {
    const { open, close } = this.dom
    open && open.removeEventListener('click', this.openMenu)
    close && close.removeEventListener('click', this.closeMenu)
  }

  updateMenu(url) {
    if (!url) return

    const { desktop, mobile, footer } = this.dom
    const location = url

    desktop.links.forEach((link) => {
      link.parentNode.classList.remove('-active')
      if (link.href === location) {
        link.parentNode.classList.add('-active')
      }
    })

    mobile.links.forEach((link) => {
      link.parentNode.classList.remove('-active')
      if (link.href === location) {
        link.parentNode.classList.add('-active')
      }
    })

    if (footer.links) {
      footer.links.forEach((link) => {
        link.parentNode.classList.remove('-active')
        if (link.href === location) {
          link.parentNode.classList.add('-active')
        }
      })
    }
  }

  update(url) {
    const footer = qs('footer', store.page.el)
    const links = footer ? qsa('a', footer) : null

    Object.assign(this.dom, {
      footer: {
        links: links,
      },
      url: url,
    })

    this.updateMenu(url)
  }

  openMenu = () => {
    const { mobile, background } = this.dom
    const menu = mobile.el

    this.state.open = true

    gsap.set(menu, { autoAlpha: 1 })
    gsap.to(background, { autoAlpha: 1 })

    gsap.from('.menu-item', {
      duration: 1.2,
      autoAlpha: 0,
      stagger: 0.07,
      y: 40,
      delay: 0.3,
      ease: 'expo.out',
    })

    gsap.set(store.body, { overflow: 'hidden', height: '100vh' })
  }

  closeMenu = () => {
    const { mobile, background } = this.dom
    const menu = mobile.el

    if (!this.state.open) return

    gsap.fromTo(
      menu,
      { autoAlpha: 1 },
      {
        duration: 0.5,
        autoAlpha: 0,
        ease: 'power3.inOut',
        onComplete: () => {
          gsap.set(menu, { autoAlpha: 0 })
          gsap.to(background, { autoAlpha: 0 })
          gsap.set(store.body, { clearProps: 'all' })
          this.state.open = false
        },
      },
    )
  }

  showLink = (e) => {
    if (this.state.active) return
    const { desktop, background } = this.dom
    const target = e.target
    const link = target.dataset.link
    const item = qs(`[data-item="${link}"]`)

    if (!item || link == this.state.hover) return

    this.state.hover = false
    desktop.el.classList.add('-rollover')
    item.classList.add('-hover')
    gsap.set(item, { autoAlpha: 1 })
    gsap.set(background, { autoAlpha: 0.9 })
  }

  hideLink = (e) => {
    if (this.state.active) return
    const { desktop, background } = this.dom
    const target = e.target
    const link = target.dataset.link
    const item = qs(`[data-item="${link}"]`)

    if (!item) return

    desktop.el.classList.remove('-rollover')
    item.classList.remove('-hover')
    gsap.set(item, { autoAlpha: 0 })
    gsap.set(background, { autoAlpha: 0 })
  }

  fixMenu = (e) => {
    const target = e.target
    const link = target.dataset.link
    const item = qs(`[data-item="${link}"]`)

    if (!item) return

    const tl = this.closeBG(item, e)
    this.state.active = true
    this.state.hover = target.dataset.link

    tl.restart()
  }

  closeBG(item, e) {
    const { background, overlay } = this.dom
    const { uniforms } = this.state
    const chars = qsa('.char-0', item)

    const tl = gsap.timeline({
      paused: true,
      onComplete: () => {
        this.state.active = false
        this.hideLink(e)
        gsap.set(background, { clearProps: 'transform', autoAlpha: 0 })
        gsap.set(overlay, { clearProps: 'all' })
        gsap.set('.links', { clearProps: 'all' })
        gsap.set(chars, { clearProps: 'opacity' })
        gsap.set(chars, { clearProps: 'visibility' })
      },
    })

    tl.fromTo(
      background,
      {
        y: 0,
      },

      {
        duration: 1,
        y: '100%',
        ease: 'power4.inOut',
      },
      0,
    )

    tl.fromTo(
      overlay,
      {
        y: 0,
      },

      {
        duration: 1,
        y: '-100%',
        ease: 'power4.inOut',
      },
      '-=1',
    )

    tl.fromTo(
      '.links',
      {
        y: 0,
      },

      {
        duration: 1,
        y: '100%',
        ease: 'power4.inOut',
      },
      '-=1',
    )

    tl.to(
      chars,
      {
        duration: 0.6,
        autoAlpha: 0,
        stagger: 0.1,
      },
      0,
    )

    if (uniforms) {
      tl.to(
        this.bend(uniforms),
        {
          progress: 1,
        },
        0,
      )
    }

    return tl
  }

  playTransition() {
    if (this.state.active || this.state.open) return

    const { overlay, background } = this.dom
    const tl = gsap.timeline({
      onComplete: () => {
        gsap.set(background, { clearProps: 'transform', autoAlpha: 0 })
        gsap.set(overlay, { clearProps: 'all' })
      },
    })

    tl.set(background, { autoAlpha: 1 })

    tl.fromTo(
      overlay,
      { y: '100%' },
      { duration: 1, y: 0, ease: 'power4.inOut' },
    )

    tl.fromTo(
      background,
      { y: '-100%' },
      { duration: 1, y: 0, ease: 'power4.inOut' },
      '-=1',
    )

    tl.fromTo(
      overlay,
      { y: 0 },
      { duration: 1, y: '-100%', ease: 'power4.out' },
    )

    tl.fromTo(
      background,
      { y: 0 },
      { duration: 1, y: '100%', ease: 'power4.out' },
      '-=1',
    )
  }

  bend = (uniforms) => {
    const uPower = uniforms.uPower

    return gsap
      .timeline({
        paused: true,
        defaults: {
          ease: 'linear',
          duration: 0.5,
        },
      })
      .to(uPower, { value: 2 })
      .to(uPower, { value: 0 })
  }

  destroy() {
    this.off()
  }

  init() {
    this.updateMenu(this.dom.url)
    this.on()
  }
}

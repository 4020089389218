import store from '../store'
import { Vec2, Plane, Program, Mesh, Texture } from 'ogl'
import gsap from 'gsap'
import vertex from '../glsl/shaders/sliderVertex.glsl'
import fragment from '../glsl/shaders/sliderFragment.glsl'

export default class Planes {
  constructor(obj = {}) {
    const gl = obj.gl
    const texture = obj.texture
    const template = obj.template

    this.plane = {}

    this.ogl = {
      gl: gl,
      texture: texture,
      template: template,
    }

    this.setup()
  }

  setup() {
    const { page } = store
    const { gl, texture, template } = this.ogl

    const tex = new Texture(gl, {
      generateMipmaps: false,
      minFilter: gl.LINEAR,
    })

    const tl = gsap.timeline({ paused: true })

    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.src = texture

    img.decode().then(() => {
      tex.image = img
      program.uniforms.uSize.value.x = img.naturalWidth
      program.uniforms.uSize.value.y = img.naturalHeight
    })

    const geometry = new Plane(gl, {
      widthSegments: 32,
      heightSegments: 32,
    })

    const reveal = template == 'img' ? 1 : 0
    const value = template == 'img' ? 0 : template == 'slider' ? 1 : 2
    const alpha = template == 'smiley' ? 0 : 1

    const program = new Program(gl, {
      vertex: vertex,
      fragment: fragment,
      transparent: true,
      uniforms: {
        uTexture: { value: tex },
        uTime: { value: 0 },
        uDiff: { value: 0 },
        uScale: { value: 1 },
        uReveal: { value: reveal },
        uAlpha: { value: alpha },
        uScaleChange: { value: 0.9 },
        uRes: { value: new Vec2(page.vw, page.vh) },
        uOffset: { value: new Vec2(0, 0) },
        uSize: { value: new Vec2(0, 0) },
        uPlaneSizes: { value: new Vec2(0, 0) },
        uType: { value: value },
        uPosY: { value: 0 },
      },
    })

    const mesh = new Mesh(gl, {
      geometry: geometry,
      program: program,
    })

    //tl.to(program.uniforms.uScale, { duration: 2, value: 0 })

    this.plane.geometry = geometry
    this.plane.program = program
    this.plane.mesh = mesh
    this.plane.texture = tex
    //this.plane.timeline = tl
    this.plane.status = false

    return this.plane
  }
}

import store from '../store'
import { Emitter } from '../core'
import {
  Renderer,
  Camera,
  Geometry,
  Program,
  Transform,
  Mesh,
  Orbit,
  Vec3,
} from 'ogl'
import glsl from 'glslify'
import vertex from '../glsl/shaders/particleVertex.glsl'
import fragment from '../glsl/shaders/particleFragment.glsl'

export default class Particles {
  constructor(obj = {}) {
    const container = obj.container || store.body

    this.scene = {
      name: 'Default',
      renderer: null,
      camera: null,
      objects: null,
      orbit: null,
      container,
    }

    this.amount = 500
    this.time = 0
    this.program = null
    this.init()
  }

  setup() {
    this.render()
    this.camera()
    this.mesh()
    store.webgl.scenes.push(this.scene)
  }

  render() {
    const { container } = this.scene
    const { page } = store
    const renderer = new Renderer({ dpr: 2, alpha: true })
    const gl = renderer.gl

    gl.clearColor(0, 0, 0, 0)
    gl.canvas.classList.add('particles-ogl')
    renderer.setSize(page.vw, page.vh)
    container.appendChild(gl.canvas)

    Object.assign(this.scene, {
      renderer,
    })
  }

  camera() {
    const { page } = store
    const { renderer } = this.scene
    const { gl } = renderer
    const camera = new Camera(gl, { fov: 35 })
    const aspectRatio = page.vw / page.vh

    camera.position.set(0, 0, 7)
    camera.lookAt([0, 0, 0])
    camera.perspective({
      aspect: aspectRatio,
    })

    const orbit = new Orbit(camera, {
      target: new Vec3(0, 0, 0),
    })

    orbit.enabled = false // Orbit will not work if canvas position absolute

    Object.assign(this.scene, {
      camera,
      orbit,
    })
  }

  mesh() {
    const { renderer } = this.scene
    const { gl } = renderer
    const objects = new Transform()
    const position = new Float32Array(this.amount * 3)
    const random = new Float32Array(this.amount * 4)

    for (let i = 0; i < this.amount; i++) {
      position.set([Math.random(), Math.random(), Math.random()], i * 3)
      random.set(
        [Math.random(), Math.random(), Math.random(), Math.random()],
        i * 4,
      )
    }

    const geometry = new Geometry(gl, {
      position: { size: 3, data: position },
      random: { size: 4, data: random },
    })

    this.program = new Program(gl, {
      vertex: glsl(vertex),
      fragment: glsl(fragment),
      transparent: true,
      depthTest: false,
      uniforms: {
        uTime: { value: 0 },
        uSize: { value: 10 },
        uColor1: { value: [255, 255, 255] },
        uColor2: { value: [218, 204, 183] },
        uColor3: { value: [227, 207, 188] },
        uAlpha: { value: 0.8 },
      },
    })

    const particles = new Mesh(gl, {
      mode: gl.POINTS,
      geometry,
      program: this.program,
    })

    particles.setParent(objects)

    Object.assign(this.scene, {
      particles,
      objects,
    })

    //this.controls()
  }

  updateMesh() {
    const { renderer } = this.scene
    const { gl } = renderer
    const objects = new Transform()
    const position = new Float32Array(this.amount * 3)
    const random = new Float32Array(this.amount * 4)

    for (let i = 0; i < this.amount; i++) {
      position.set([Math.random(), Math.random(), Math.random()], i * 3)
      random.set(
        [Math.random(), Math.random(), Math.random(), Math.random()],
        i * 4,
      )
    }

    const geometry = new Geometry(gl, {
      position: { size: 3, data: position },
      random: { size: 4, data: random },
    })

    const particles = new Mesh(gl, {
      mode: gl.POINTS,
      geometry,
      program: this.program,
    })
    particles.setParent(objects)

    Object.assign(this.scene, {
      particles,
      objects,
    })
  }

  tick = () => {
    const { renderer, camera, objects, orbit, particles } = this.scene

    this.time += 1 / 300

    particles.rotation.x = Math.sin(this.time * 0.005) * 0.1
    particles.rotation.y = Math.cos(this.time * 0.005) * 0.15
    particles.rotation.z += 0.005

    objects.children.forEach((obj) => {
      obj.program.uniforms.uTime.value = this.time
    })

    orbit.update()
    //fpsGraph.begin()
    renderer.render({ scene: objects, camera: camera })
    //fpsGraph.end()
  }

  on() {
    Emitter.on('tick', this.tick)
    Emitter.on('resize', this.resize)
  }

  off() {
    Emitter.off('tick', this.tick)
    Emitter.off('resize', this.resize)
  }

  resize = () => {
    const { page } = store
    const aspectRatio = page.vw / page.vh

    this.scene.camera.perspective({
      aspect: aspectRatio,
    })

    this.scene.renderer.setSize(page.vw, page.vh)
  }

  destroy() {
    this.off()
  }

  init() {
    this.setup()
    this.on()
  }
}

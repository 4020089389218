import store from '../store'

import { Emitter } from '../core'
import {
  Renderer,
  Box,
  Camera,
  Transform,
  Program,
  Mesh,
  Sphere,
  Cylinder,
} from 'ogl'
import vertex from '../glsl/shaders/boxesVertex.glsl'
import fragment from '../glsl/shaders/boxesFragment.glsl'
import glowFrag from '../glsl/shaders/glowBoxFragment.glsl'
import gsap from 'gsap'

export default class boxGL {
  constructor(obj = {}) {
    this.dom = {
      container: obj.container,
    }

    this.init()
  }

  setup() {
    const { page } = store
    const { container } = this.dom
    const renderer = new Renderer({ dpr: 2, alpha: true })
    const gl = renderer.gl
    const camera = new Camera(gl, { fov: 35 })
    const scene = new Transform()

    const aspectRatio = page.vw / page.vh

    gl.clearColor(0, 0, 0, 0)
    camera.position.set(0, 0, 5)
    camera.lookAt([0, 0, 0])
    camera.perspective({
      aspect: aspectRatio,
    })

    renderer.setSize(page.vw, page.vh)

    this.ogl = {
      renderer,
      gl,
      camera,
      scene,
      //  orbit,
    }

    gl.canvas.classList.add('gl-boxes')
    container.appendChild(gl.canvas)
  }

  mesh() {
    const { renderer, scene } = this.ogl
    const { gl } = renderer
    const tubes = []
    const group = new Transform()
    const cubeGeometry = new Box(gl)
    const sphereGeometry = new Sphere(gl)
    const cylinderGeometry = new Cylinder(gl, {
      height: 20,
      radialSegments: 16,
      radiusTop: 0.12,
      radiusBottom: 0.12,
    })

    const positions = cubeGeometry.attributes.position.data

    const cubeProgram = new Program(gl, {
      vertex: vertex,
      fragment: fragment,
      transparent: true,
      uniforms: {
        uAlpha: { value: 1 },
        uProgress: { value: 1 },
        uType: { value: 1 },
      },
      //cullFace: null,
      // cullFace: gl.FRONT_AND_BACK,
      //depthTest: false,
      //depthWrite: false,
    })

    const glowProgram = new Program(gl, {
      vertex: vertex,
      fragment: glowFrag,
      transparent: true,
      uniforms: {
        uAlpha: { value: 1 },
        uProgress: { value: 1 },
        uType: { value: 1 },
      },
    })

    const tubesProgram = new Program(gl, {
      vertex: vertex,
      fragment: fragment,
      transparent: true,
      uniforms: {
        uAlpha: { value: 0 },
        uProgress: { value: 0 },
        uType: { value: 0 },
      },
      cullFace: null,
    })

    const ballsProgram = new Program(gl, {
      vertex: vertex,
      fragment: fragment,
      transparent: true,
      uniforms: {
        uAlpha: { value: 0 },
        uProgress: { value: 0 },
        uType: { value: 0 },
      },
      cullFace: null,
    })

    const cube = new Mesh(gl, {
      geometry: cubeGeometry,
      program: cubeProgram,
    })
    cube.position.set(0, 0, 0)
    cube.name = 'Cube'
    cube.setParent(group)

    const glow = new Mesh(gl, {
      geometry: cubeGeometry,
      program: glowProgram,
    })
    glow.position.set(0, 0, 0)
    glow.name = 'Glow'
    glow.scale.set(0)
    glow.rotation.set(-0.34, 0.8, -0.34)
    glow.setParent(scene)

    for (let i = 0; i < 8; i++) {
      let x = positions[i * 3]
      let y = positions[i * 3 + 1]
      let z = positions[i * 3 + 2]

      const ball = new Mesh(gl, {
        geometry: sphereGeometry,
        program: ballsProgram,
      })
      ball.name = 'Ball'
      ball.scale.set(0.03)
      ball.position.set(x, y, z)
      ball.setParent(group)
    }

    for (let i = 0; i < 12; i++) {
      let x = positions[i * 3]
      let y = positions[i * 3 + 1]
      let z = positions[i * 3 + 2]

      const cylinder = new Mesh(gl, {
        geometry: cylinderGeometry,
        program: tubesProgram,
      })
      cylinder.name = 'Tube'
      cylinder.scale.set(0.05)
      cylinder.position.set(x, y, z)
      cylinder.setParent(group)
      tubes.push(cylinder)
    }

    tubes.forEach((t, i) => {
      if (i == 0) {
        t.position[1] = -0.5
        t.position[2] = 0
        t.rotation.set(-Math.PI / 2, 0, 0)
      }

      if (i == 1) {
        t.position[0] = 0
        t.rotation.set(0, 0, Math.PI / 2)
      }

      if (i == 2) {
        t.position[0] = 0
        t.rotation.set(0, 0, Math.PI / 2)
      }

      if (i == 3 || i == 4 || i == 5 || i == 11) t.position[1] = 0

      if (i == 6) {
        t.position[0] = 0
        t.rotation.set(0, 0, Math.PI / 2)
      }

      if (i == 7 || i == 8 || i == 9) {
        t.position[2] = 0
        t.rotation.set(-Math.PI / 2, 0, 0)
      }

      if (i == 10) {
        t.position[0] = 0
        t.rotation.set(0, 0, Math.PI / 2)
      }
    })

    group.rotation.set(0.34, 0.8, 0.34)
    group.setParent(scene)

    Object.assign(this.ogl, {
      group,
    })
  }

  tick = () => {
    const { renderer, camera, scene, orbit } = this.ogl

    this.time += 1 / 300
    scene.children.forEach((obj) => {})

    renderer.render({ scene: scene, camera: camera })
  }

  animate = ({ index, direction }) => {
    const { sniff } = store
    const { breakpoints } = sniff
    const { group, scene } = this.ogl
    const glow = scene.children[0]
    const size = breakpoints.M_UP ? (breakpoints.L_UP ? 1.2 : 1) : 0.75

    if (direction == 'down') {
      if (index == 2) {
        gsap.fromTo(
          group.scale,
          { x: 0, y: 0, z: 0 },
          { duration: 0.8, x: size, y: size, z: size, ease: 'power3.inOut' },
        )
      }

      if (index == 3) {
        gsap.to(group.rotation, {
          duration: 1,
          x: 0.34,
          y: -0.8,
          z: -0.34,
          ease: 'power3.inOut',
        })
      }

      if (index == 4) {
        gsap.to(group.rotation, {
          duration: 1,
          x: -0.34,
          y: 0.8,
          z: -0.34,
          onComplete: () => {
            group.children.forEach((el) => {
              if (el.name == 'Cube') el.program.transparent = false
            })
          },
        })
      }

      if (index == 5) {
        gsap.to(group.scale, {
          duration: 1,
          x: size / 1.8,
          y: size / 1.8,
          z: size / 1.8,
          ease: 'power3.out',
        })
        gsap.to(group.rotation, {
          duration: 1,
          x: 0.34,
          y: 0.8,
          z: 0.34,
          ease: 'power3.out',
        })
        gsap.to(glow.scale, {
          duration: 1,
          x: size,
          y: size,
          z: size,
          ease: 'power3.out',
        })
      }

      group.children.forEach((el) => {
        switch (el.name) {
          case 'Tube':
            if (index == 1) {
              gsap.set(el.scale, { y: 0 })
              gsap.set(el.program.uniforms.uAlpha, { value: 1 })
            }
            if (index == 3)
              gsap.fromTo(
                el.scale,
                { y: 0 },
                { duration: 1, y: 0.05, ease: 'power3.inOut' },
              )

            if (index == 4)
              gsap.to(el.program.uniforms.uAlpha, { duration: 1, value: 0 })

            break

          case 'Ball':
            if (index == 2)
              gsap.to(el.program.uniforms.uAlpha, {
                duration: 1,
                value: 1,
                ease: 'power3.inOut',
              })
            if (index == 4) gsap.set(el.program.uniforms.uAlpha, { value: 0 })
            break
          case 'Cube':
            if (index == 4) {
              gsap.to(el.program.uniforms.uProgress, {
                duration: 1,
                value: 0,
              })
            }

            break
        }
      })
    } else {
      if (index == 1) {
        gsap.to(group.scale, {
          duration: 1,
          x: 0,
          y: 0,
          z: 0,
          ease: 'power3.inOut',
        })
      }

      if (index == 2) {
        gsap.to(group.rotation, {
          duration: 1,
          x: 0.34,
          y: 0.8,
          z: 0.34,
          ease: 'power3.out',
        })
      }

      if (index == 3) {
        group.children.forEach((el) => {
          if (el.name == 'Cube') el.program.transparent = true
        })
        gsap.to(group.rotation, {
          duration: 1,
          x: 0.34,
          y: -0.8,
          z: -0.34,
          ease: 'power3.inOut',
        })
      }

      if (index == 4) {
        gsap.to(group.scale, {
          duration: 1,
          x: size,
          y: size,
          z: size,
          ease: 'power3.inOut',
        })
        gsap.to(group.rotation, {
          duration: 1,
          x: -0.34,
          y: 0.8,
          z: -0.34,
          ease: 'power3.inOut',
        })
        gsap.to(glow.scale, {
          duration: 1,
          x: 0,
          y: 0,
          z: 0,
          ease: 'power3.inOut',
          onComplete: () => {
            group.children.forEach((el) => {
              if (el.name == 'Cube') el.program.transparent = false
            })
          },
        })
      }

      group.children.forEach((el) => {
        switch (el.name) {
          case 'Tube':
            if (index == 2)
              gsap.fromTo(el.scale, { y: 0.05 }, { duration: 1, y: 0 })

            if (index == 3) gsap.set(el.program.uniforms.uAlpha, { value: 1 })

            break

          case 'Ball':
            if (index == 3) gsap.set(el.program.uniforms.uAlpha, { value: 1 })

          case 'Cube':
            if (index == 3)
              gsap.to(el.program.uniforms.uProgress, { duration: 1, value: 1 })
        }
      })
    }
  }

  on() {
    Emitter.on('tick', this.tick)
    Emitter.on('resize', this.resize)
    Emitter.on('flip', this.animate)
  }

  off() {
    Emitter.off('tick', this.tick)
    Emitter.off('resize', this.resize)
    Emitter.off('flip', this.animate)
  }

  resize = () => {
    const { page } = store
    const aspectRatio = page.vw / page.vh

    this.ogl.camera.perspective({
      aspect: aspectRatio,
    })

    this.ogl.renderer.setSize(page.vw, page.vh)
  }

  destroy() {
    this.off()
  }

  init() {
    this.setup()
    this.mesh()
    this.on()
  }
}

import { qs, qsa } from '../utils'
import gsap from 'gsap'

export function Cases() {
  // Table Cascade
  const caseTable = qsa('.caseTable')

  if (caseTable[0]) {
    caseTable.forEach((table) => {
      let rows = qsa('.table-row', table)
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: table,
          start: 'top 70%',
          end: 'bottom 0%',
          once: true,
        },
      })

      if (rows[0]) tl.from(rows, { autoAlpha: 0, stagger: 0.1 })
    })
  }

  // Images
  const caseImages = qsa('.caseImages')

  if (caseImages[0]) {
    caseImages.forEach((box) => {
      let images = qsa('.cover', box)
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: box,
          start: 'top 70%',
          end: 'bottom 0%',
          once: true,
        },
      })

      if (images[0]) {
        tl.fromTo(
          images,
          { y: 30, autoAlpha: 0 },
          { y: 0, autoAlpha: 1, stagger: 0.2 },
        )
      }

      // Slide images with touch on mobile

      if (images.length > 1) {
        let touchstartX = 0
        let touchendX = 0
        let activeImg = 0

        function checkDirection() {
          let content = box.querySelector('.caseImages-content')
          if (touchendX < touchstartX) {
            // left
            let width = 0 - (images[0].offsetWidth + 12.8) * (activeImg + 1)
            let total = (images[0].offsetWidth + 12.8) * (images.length - 1)
            if (width >= 0 - total) {
              gsap.to(content, { x: width })
              activeImg = activeImg + 1
            }
          }
          if (touchendX > touchstartX) {
            // right
            let width = 0 - (images[0].offsetWidth + 12.8) * (activeImg - 1)

            if (activeImg > 0) {
              gsap.to(content, { x: width })
              activeImg = activeImg - 1
            }
          }
        }

        box.addEventListener('touchstart', (e) => {
          touchstartX = e.changedTouches[0].screenX
        })

        box.addEventListener('touchend', (e) => {
          touchendX = e.changedTouches[0].screenX
          checkDirection()
        })
      }
    })
  }

  // Image & Text slide in
  const caseSquares = qsa('.caseSquares')

  if (caseSquares[0]) {
    caseSquares.forEach((square) => {
      let images = square.querySelectorAll('.caseSquares-anim')
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: square,
          start: 'top 80%',
          end: 'bottom 0%',
          once: true,
        },
      })

      tl.fromTo(images, { y: 120 }, { y: 0, stagger: 0.05 })
    })
  }

  // Columns scroll
  const caseColumns = qsa('.caseColumns')

  if (caseColumns[0]) {
    caseColumns.forEach((cols) => {
      let columns = cols.querySelectorAll('.caseColumns-column')

      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: cols,
          start: 'top 70%',
          end: 'bottom 0%',
          scrub: 0.1,
        },
      })

      tl.from(columns, { marginTop: '12rem' })
    })
  }

  // Blocks
  const caseBlocks = qsa('.caseBlocks')

  if (caseBlocks[0]) {
    caseBlocks.forEach((block) => {
      let images = block.querySelectorAll('img')

      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: block,
          start: 'top 120%',
          scrub: true,
          once: true,
        },
      })

      tl.from(images, { duration: 0.6, scale: 1.05, stagger: 0.05 })
    })
  }

  // Quotes
  const caseQuote = qsa('.caseQuote')

  if (caseQuote[0]) {
    caseQuote.forEach((quotes) => {
      let image = qs('img', quotes)

      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: quotes,
          start: 'top 70%',
          end: 'bottom 0%',
          once: true,
        },
      })

      tl.from(image, { y: -30 })
    })
  }

  // Grid
  const caseGrid = qsa('.caseGrid')

  if (caseGrid[0]) {
    caseGrid.forEach((grid) => {
      let image = qsa('.caseGrid-box', grid)

      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: grid,
          start: 'top 70%',
          end: 'bottom 0%',
          once: true,
        },
      })

      tl.fromTo(
        image,
        { autoAlpha: 0, y: 30 },
        { y: 0, autoAlpha: 1, stagger: 0.1 },
      )
    })
  }
}

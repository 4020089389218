import { qsa } from '../utils'
import gsap from 'gsap'

export function BgScroll() {
  const black = qsa('.largeTitle.-black')

  if (black[0]) {
    black.forEach((item) => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: item,
          start: 'top +100%',
          end: 'top',
          scrub: true,
        },
      })

      tl.to('.bg-block', { duration: 0.1, backgroundColor: '#262523' })
      tl.fromTo(
        '.bg-block',
        { scaleY: 0 },
        {
          duration: 0.6,
          scaleY: 1,
          stagger: 0.05,
        },
      )
    })
  }

  if (black[0]) {
    black.forEach((item) => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: item,
          start: 'bottom +100%',
          end: 'bottom',
          scrub: true,
        },
      })

      tl.to('.bg-block', {
        duration: 0.6,
        scaleY: 0,
        stagger: 0.05,
      })
    })
  }
}

import store from '../store'
import Emitter from './Emitter'
import { Sniffer, lerp } from '../utils'
import { gsap } from 'gsap'

export default class GlobalRaf {
  constructor() {
    this.scroll = {
      target: 0,
      current: 0,
      rounded: 0,
      ease: 0.115,
    }

    this.mouse = {
      x: 0,
      y: 0,
      target: null,
    }
  }

  tick = () => {
    const { target, current, ease } = this.scroll
    this.scroll.current = lerp(current, target, ease)
    this.scroll.rounded = Math.round(current * 100) / 100
    let diff = (target - current) * 0.005

    Emitter.emit('tick', {
      target: target,
      current: this.getSmooth(),
      mouse: this.mouse,
      diff: diff,
    })
  }

  clamp() {
    this.scroll.target = Math.min(Math.max(this.scroll.target, 0), store.page.h)
  }

  getSmooth() {
    if (!Sniffer.isDevice) {
      return this.scroll.rounded
    } else {
      return window.pageYOffset
    }
  }

  onScroll = ({ y }) => {
    if (store.flags.locked) return
    this.scroll.target += y
    this.clamp()
  }

  move = ({ x, y, target }) => {
    this.mouse.x = x
    this.mouse.y = y
    this.mouse.target = target
  }

  reset() {
    this.scroll.target = this.scroll.current = this.scroll.rounded = 0
  }

  on() {
    gsap.ticker.fps(-1)
    gsap.ticker.add(this.tick)
    Emitter.on('scroll', this.onScroll)
    Emitter.on('mousemove', this.move)
  }
}

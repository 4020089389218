import { qs, qsa } from '../utils'
import gsap from 'gsap'

export function CapableIn() {
  const section = qs('.oneSection')

  if (section) {
    const title = qsa('.slide-title')
    const chars = qsa('.char-1', title[0])

    gsap.set(title, { autoAlpha: 1 })

    gsap.from(chars, {
      duration: 0.8,
      y: '-200%',
      stagger: 0.045,
      delay: 0.3,
    })
  }
}

import { qsa } from '../utils'
import gsap from 'gsap'

export function Flipped({ current, previous, direction, index }) {
  const charsPrev = qsa('.char-1', previous)
  const charsCurrent = qsa('.char-1', current)
  const pPrev = qsa('.p', previous)
  const pCurrent = qsa('.p', current)
  const charsStagger = 0.025
  const pStagger = 0.045

  if (index == 1) {
    gsap.to('.oneSection-bg', {
      duration: 0.8,
      scale: 1,
      rotate: 0,
      ease: 'power3.inOut',
    })
  }

  if (index == 0) {
    gsap.to('.oneSection-bg', {
      duration: 0.8,
      scale: 0,
      rotate: -45,
      ease: 'power3.inOut',
    })
  }

  gsap.set(current, { autoAlpha: 1 })

  if (index == 0 || (index == 1 && direction == 'down')) {
    gsap.set(previous, { autoAlpha: 0 })

    return
  }

  if (direction == 'down') {
    if (charsPrev[0]) {
      gsap.fromTo(
        charsPrev,
        { duration: 0.5, y: '0%' },
        {
          duration: 0.5,
          y: '102%',
          stagger: charsStagger,
          ease: 'power3.inOut',
        },
      )
    }

    if (pPrev[0]) {
      gsap.fromTo(
        pPrev,
        { duration: 0.5, y: '0', autoAlpha: 1 },
        {
          duration: 0.5,
          y: '10',
          autoAlpha: 0,
          stagger: pStagger,
          ease: 'power3.inOut',
        },
      )
    }

    if (charsCurrent[0]) {
      gsap.fromTo(
        charsCurrent,
        { duration: 0.5, y: '-101%' },
        {
          duration: 0.5,
          y: '0%',
          stagger: charsStagger,
          delay: 0.4,
          ease: 'power3.inOut',
        },
      )
    }

    if (pCurrent[0]) {
      gsap.fromTo(
        pCurrent,
        { duration: 0.5, y: '-10', autoAlpha: 0 },
        {
          duration: 0.5,
          y: '0',
          autoAlpha: 1,
          stagger: pStagger,
          delay: 0.4,
          ease: 'power3.inOut',
        },
      )
    }
  } else {
    if (charsPrev[0]) {
      gsap.fromTo(
        charsPrev,
        { duration: 0.5, y: '0%' },
        {
          duration: 0.5,
          y: '-101%',
          stagger: charsStagger,
          ease: 'power3.inOut',
        },
      )
    }

    if (pPrev[0]) {
      gsap.fromTo(
        pPrev,
        { duration: 0.5, y: '0', autoAlpha: 1 },
        {
          duration: 0.5,
          y: '10',
          autoAlpha: 0,
          stagger: pStagger,
          ease: 'power3.inOut',
        },
      )
    }

    if (charsCurrent[0]) {
      gsap.fromTo(
        charsCurrent,
        { duration: 0.5, y: '102%' },
        {
          duration: 0.5,
          y: '0%',
          stagger: 0.04,
          delay: 0.4,
          ease: 'power3.inOut',
        },
      )
    }

    if (pCurrent[0]) {
      gsap.fromTo(
        pCurrent,
        { duration: 0.5, y: '-10', autoAlpha: 0 },
        {
          duration: 0.5,
          y: '0',
          autoAlpha: 1,
          stagger: pStagger,
          delay: 0.4,
          ease: 'power3.inOut',
        },
      )
    }
  }
}

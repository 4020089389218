import store from '../store'
import Emitter from './Emitter'
import FontFaceObserver from 'fontfaceobserver'

export default class Loader {
  constructor(obj = {}) {
    const fonts = obj.fonts
    const images = obj.images

    this.load = {
      fonts,
      images,
    }

    this.setup()
  }

  setup() {
    const { fonts, images } = this.load
    const promises = []

    if (fonts) {
      fonts.forEach((font) => {
        const el = new FontFaceObserver(font)
        promises.push(el.load())
      })
    }

    if (images) {
      images.forEach((url) => {
        const el = new Promise((resolve, reject) => {
          let image = new Image()
          image.onload = () => resolve(image)
          const msg = `Could not load image at ${url}`
          image.onerror = () => reject(new Error(msg))
          image.src = url
        })

        promises.push(el)
      })
    }

    Promise.all(promises).then(() => {
      store.LOADED = true
      Emitter.emit('loaded')
    })
  }
}

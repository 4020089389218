import store from './store'
import H from './highway/H'
import { GlobalRaf, GlobalResize, Scroll, Mouse, Loader, Emitter } from './core'
import { Sniffer, Grid, getViewport, getWindowSizes } from './utils'
import { Preloader } from './animations'
import FontFaceObserver from 'fontfaceobserver'
import lazySizes from 'lazysizes'

export default class App {
  constructor() {
    console.log(
      `%c${store.title} \nMade with ❤️ by ${store.author.name} \n↳ ${store.author.link}`,
      'color: #342f2f',
    )

    // Initial Settings
    store.page.vw = getViewport().width
    store.page.vh = getViewport().height
    store.sniff.breakpoints = getWindowSizes()
    store.sniff.browser = Sniffer.sniff

    // LazySizes clases
    lazySizes.cfg.lazyClass = 'lazy'
    lazySizes.cfg.loadedClass = 'loaded'

    this.init()
  }

  core() {
    // Highway
    new H()
    // Global Request Animation Frame
    store.RAF = new GlobalRaf()
    // Global Scroll
    store.SCROLL = new Scroll({ smooth: Sniffer.isDesktop })
    // Global Mouse
    store.MOUSE = new Mouse()
    // Global Resize
    store.RESIZE = new GlobalResize()
  }

  grid() {
    new Grid({
      background: '#a25b4c',
      desktop: {
        columns: 18,
      },
      mobile: {
        columns: 4,
        gutter: 27,
        size: 390,
      },
    })
  }

  load() {
    Preloader()

    const fontA = new FontFaceObserver('Basis', 10000)
    const fontB = new FontFaceObserver('Messina Sans', 10000)

    Promise.all([fontA.load(), fontB.load()]).then(() => {
      store.flags.loaded = true
      store.RESIZE.resize()
      Emitter.emit('loaded')
    })
  }

  init() {
    this.core()
    this.grid()
    this.load()

    store.RAF.on()
    store.SCROLL.on()
    store.MOUSE.on()
  }
}

import store from '../store'
import { qs } from '../utils'
import gsap from 'gsap'
import { Emitter } from '../core'

export function Preloader() {
  const { page, sniff } = store
  const preloader = qs('.preloader')
  const icon = qs('.icon', preloader)
  const glitch = qs('.glitch', preloader)
  const logo = qs('.logo', preloader)
  const tl = gsap.timeline()
  const pagew = page.vw < page.vh ? page.vh : page.vw
  const w = ((pagew / 2 - 7) * 100) / page.vw

  tl.to(preloader, {
    duration: 0.8,
    delay: 0.8,
    boxShadow: `inset 0 0 0 ${w}vw rgb(236, 236, 236)`,
    onComplete: () => {
      gsap.set('.background', { autoAlpha: 0 })
      gsap.set('.bar', { autoAlpha: 1 })
      gsap.set('.dot', { autoAlpha: 1 })
      gsap.set(icon, { autoAlpha: 1 })
      gsap.set(preloader, {
        boxShadow: `inset 0 0 0 ${pagew}px rgb(236, 236, 236)`,
      })
    },
  })

  tl.fromTo(
    icon,
    { x: '100%' },
    { duration: 0.6, x: '50%', ease: 'power2.inOut' },
  )
  tl.fromTo(
    '.bar',
    { x: '110%' },
    { duration: 0.6, x: '160%', ease: 'power2.inOut' },
    '-=0.6',
  )
  tl.to('.dot', { duration: 0.6, x: '11rem', ease: 'power2.inOut' }, '-=0.6')
  tl.to('.dot', {
    duration: 0.6,
    skewX: -24,
    scaleX: 1.14,
    scaleY: 7.6,
    y: 14,
    x: 36,
    ease: 'power2.inOut',
  })
  tl.to('.bar', { duration: 0.6, x: '120%', ease: 'power2.inOut' }, '-=0.6')
  tl.to(
    icon,
    {
      duration: 0.6,
      x: '80%',
      ease: 'power2.inOut',
      onComplete: () => {
        glitch.classList.add('-animate')
        gsap.set(glitch, { autoAlpha: 1 })
        gsap.set(logo, { autoAlpha: 0 })
      },
    },
    '-=0.6',
  )
  tl.set(glitch, {
    autoAlpha: 0,
    delay: 0.78,
    onComplete: () => {
      gsap.set(preloader, { autoAlpha: 0 })
      gsap.set(store.page.el, { autoAlpha: 1 })

      Emitter.emit('preloaded')
    },
  })
}

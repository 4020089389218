import store from '../store'
import { qs, qsa } from '../utils'
import gsap from 'gsap'

export function LargeTitle() {
  const largeTitle = qsa('.largeTitle')

  if (largeTitle[0]) {
    largeTitle.forEach((section) => {
      const title = qs('.line-0', section)

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: section,
          start: 'top top',
          end: 'bottom bottom',
          scrub: true,
        },
      })

      tl.to(title, { scale: 0.35 })
    })
  }
}

export function LargeTitleIn() {
  const largeTitle = qs('.largeTitle-txt')

  if (largeTitle) {
    const chars = qsa('.char-1', largeTitle)

    gsap.set(largeTitle, { autoAlpha: 1 })
    gsap.from(chars, {
      duration: 0.8,
      y: '-200%',
      stagger: 0.05,
      delay: 0.3,
    })
  }
}

import { qsa } from '../utils'
import gsap from 'gsap'

export function SwirlModal() {
  const block = qsa('.swirlBlock-modal')

  if (block[0]) {
    block.forEach((item) => {
      const container = item.parentNode
      const lines = qsa('.line-0', item)

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          start: 'center',
          end: 'bottom',
          scrub: true,
        },
      })

      tl.to(item, { duration: 0.8, autoAlpha: 1 })
      tl.from(
        lines,
        { duration: 0.8, autoAlpha: 0, y: 20, stagger: 0.05 },
        '-=0.6',
      )
    })
  }
}

import store from '../store'
import { Emitter } from '../core'
import { qs, bindAll, lerp, bounds } from '../utils'
import gsap from 'gsap'

export default class Cursor {
  constructor(obj = {}) {
    bindAll(this, 'run', 'enter', 'leave', 'resize')

    const container = obj.container
    const markers = obj.markers

    this.dom = {
      container,
      markers,
    }

    this.settings = {
      last: {
        x: 0,
        y: 0,
      },
      position: {
        x: 0,
        y: 0,
      },
      ease: 0.12,
      alpha: 0,
      animating: false,
      rotate: 0,
    }

    this.state = {
      on: false,
      onPlace: true,
      animating: false,
    }

    this.init()
  }

  setup() {
    const { page } = store
    const { vw, vh } = page
    const { container } = this.dom
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const scale = window.devicePixelRatio || 1

    ctx.clearRect(0, 0, vw, vh)
    canvas.classList.add('cursor')
    canvas.width = vw * scale
    canvas.height = vh * scale

    this.canvas = {
      canvas,
      ctx,
    }

    container.appendChild(canvas)
  }

  getCursors() {
    const { markers } = this.dom

    this.markers = []

    markers.forEach((marker, i) => {
      const size = marker.size || 60
      const asset = marker.icon
      const skin = marker.skin
      const layout = marker.layout // For customization
      const rect = bounds(marker.el)
      const y = rect ? rect.top + size / 2 : 0
      const x = rect ? rect.right - size / 2 : 0
      const pos = { x, y }
      const visible = false
      const radius = 0
      const alpha = 0
      const color = qs('[data-color]', marker.el)

      if (asset) {
        const img = new Image()
        img.onload = () => {
          this.markers[i].icon = img
        }
        img.src = asset
      }

      // Custom for Elva
      const border = new Image()
      border.onload = () => {
        this.markers[i].border = border
      }
      border.src =
        skin == 'dark' ? '/images/dark-text.png' : '/images/light-text.png'

      const obj = {
        marker,
        pos,
        rect,
        size,
        layout,
        visible,
        radius,
        alpha,
        color,
      }

      this.markers.push(obj)
    })
  }

  updateCursor() {
    this.markers.forEach((marker) => {
      this.draw(marker)
    })
  }

  clearCanvas() {
    const { ctx } = this.canvas
    const { page } = store
    ctx.clearRect(0, 0, page.vw, page.vh)
  }

  draw({ icon, border, visible, radius, alpha, color }) {
    const { ctx } = this.canvas
    const { last } = this.settings
    const { page } = store
    const rgb = color.dataset.color
    let img

    this.settings.position.x = last.x
    this.settings.position.y = last.y

    if (visible) {
      if (icon) img = icon
      ctx.clearRect(0, 0, page.vw, page.vh)

      if (img) {
        let w = radius * 2
        let x = this.settings.position.x - w / 2
        let y = this.settings.position.y - w / 2
        ctx.globalAlpha = 1
        ctx.beginPath()
        ctx.rotate(0)
        ctx.drawImage(img, x, y, w, w)
      }

      if (border) {
        let w = radius * 2.5
        let x = this.settings.position.x - w / 2
        let y = this.settings.position.y - w / 2
        ctx.globalAlpha = 1
        ctx.beginPath()
        ctx.translate(x + w / 2, y + w / 2)
        ctx.rotate((this.settings.rotate * Math.PI) / 180)
        ctx.translate(-x - w / 2, -y - w / 2)
        ctx.drawImage(border, x, y, w, w)
        ctx.setTransform(1, 0, 0, 1, 0, 0)
      }
    }
  }

  run({ mouse }) {
    this.mouse = mouse
    this.settings.last.x = lerp(this.settings.last.x, mouse.x, 0.1)
    this.settings.last.y = lerp(this.settings.last.y, mouse.y, 0.1)
    this.settings.rotate += 1
    this.updateCursor()
  }

  enter = (e) => {
    this.state.on = true
    if (this.state.animating) return
    const target = e.target
    this.state.animating = true
    this.markers.forEach((el, i) => {
      const { marker, size } = el
      const delay = 0.2
      if (marker.el == target) this.markers[i].visible = true
      gsap.to(this.markers[i], {
        duration: 0.3,
        radius: size,
        alpha: 1,
        delay: delay,
        onComplete: () => {
          this.state.animating = false

          if (!this.state.on) {
            if (marker.el == target) this.markers[i].visible = false
            gsap.set(this.markers[i], { radius: 0, alpha: 0 })
            this.clearCanvas()
          }
        },
      })
    })
  }

  leave = (e) => {
    this.state.on = false
    if (this.state.animating) return
    const target = e.target
    this.state.animating = true
    this.markers.forEach((el, i) => {
      if (this.state.on) return
      const { marker } = el
      gsap.to(this.markers[i], {
        duration: 0.3,
        radius: 0,
        alpha: 0,
        onComplete: () => {
          if (marker.el == target) this.markers[i].visible = false
          this.state.animating = false
        },
      })
    })
  }

  mouseDown() {
    this.markers.forEach((el, i) => {
      gsap.to(this.markers[i], { duration: 0.3, radius: 50 })
    })
  }

  mouseUp() {
    this.markers.forEach((el, i) => {
      gsap.to(this.markers[i], { duration: 0.3, radius: 60 })
    })
  }

  on() {
    const { cursor } = this.dom
    Emitter.on('tick', this.run)
    Emitter.on('resize', this.resize)

    this.markers.forEach(({ marker }) => {
      marker.el.addEventListener('mousedown', (e) => this.mouseDown(e))
      marker.el.addEventListener('mouseup', (e) => this.mouseUp(e))
      marker.el.addEventListener('mouseenter', (e) => this.enter(e))
      marker.el.addEventListener('mouseleave', (e) => this.leave(e))
    })
  }

  off() {
    const { cursor } = this.dom
    Emitter.off('tick', this.run)
    Emitter.off('resize ', this.resize)

    this.markers.forEach(({ marker }) => {
      marker.el.removeEventListener('mousedown', (e) => this.mouseDown(e))
      marker.el.removeEventListener('mouseup', (e) => this.mouseUp(e))
      marker.el.removeEventListener('mouseenter', (e) => this.enter(e))
      marker.el.removeEventListener('mouseleave', (e) => this.leave(e))
    })
  }

  resize = () => {
    this.canvas.canvas.width = store.page.vw
    this.canvas.canvas.height = store.page.vh
    this.getCursors()
  }

  destroy() {
    this.off()
    this.cursor = null
  }

  init() {
    this.setup()
    this.getCursors()
    this.on()
    this.resize()
  }
}

export default {
  title: 'Elva Design Group',
  author: {
    name: 'Non-Linear',
    link: 'https://www.non-linear.studio/',
  },
  header: null,
  html: document.querySelector('html'),
  body: document.body,
  main: document.querySelector('main'),
  page: {
    el: null,
    vw: 0,
    vh: 0,
    h: 0,
  },
  sniff: {
    browser: null,
    breakpoints: null,
    orientation: null,
  },
  RAF: null,
  MOUSE: null,
  SCROLL: null,
  LOADED: false,
  smooth: {
    container: null,
    height: 0,
  },
  webgl: {
    scenes: [],
    slider: {
      dragging: false,
      planes: [],
    },
    images: {
      planes: [],
    },
    smiley: {
      planes: [],
      posY: 0,
      sprite: 0,
      gooey: 0,
    },
    swirl: {
      planes: [],
      posY: 0,
    },
  },
  flags: {
    smooth: true,
    locked: true,
    resize: false,
    loaded: false,
  },
}

import store from '../store'
import { qs, qsa, bounds } from '../utils'
import gsap from 'gsap'

export function HeroEmojis() {
  const { sniff } = store
  const hero = qs('.heroEmojis') || qs('.heroLegal')

  if (hero) {
    const lines = qsa('.line-0', hero)
    const icon = qsa('.icon', hero)
    const letter = qsa('.inner', hero)
    const circle = qs('.circle', hero)
    const tag = qs('.heroEmojis-tag')

    const description =
      qs('.heroLegal-description') || qs('.heroEmojis-description')
    const descriptionLines = qsa('.line-0', description)
    const tl2 = gsap.timeline({ repeat: -1, repeatDelay: 0.5, paused: true })
    const tl1 = gsap.timeline({ repeat: -1, repeatDelay: 0.5, paused: true })
    const w = circle ? bounds(circle).width : 0
    const isPhone = sniff.browser.isPhone
    const isDevice = sniff.browser.isDevice

    gsap.set(hero, { autoAlpha: 1 })

    icon.forEach((el, i) => {
      if (i % 2 == 0) {
        tl1.to(letter[i], { duration: 0.4, autoAlpha: 0, delay: 0.5 }, 0)
        tl1.to(icon[i], { duration: 0.4, autoAlpha: 1 }, '-=0.4')
      } else {
        tl2.to(letter[i], { duration: 0.4, autoAlpha: 0, delay: 0.5 }, 0)
        tl2.to(icon[i], { duration: 0.4, autoAlpha: 1 }, '-=0.4')
      }
    })

    lines.forEach((line) => {
      const chars = qsa('.char-1', line)

      if (chars[0]) {
        gsap.from(chars, {
          duration: 0.8,
          y: '-101%',
          stagger: 0.05,
          onComplete: () => {
            tl1.play()
            setTimeout(() => {
              tl2.play()
            }, 500)
          },
        })
      }
    })

    if (tag) gsap.from(tag, { duration: 0.4, y: -20, autoAlpha: 0, delay: 0.5 })

    if (description) {
      gsap.from(descriptionLines, {
        duration: 0.8,
        y: -20,
        autoAlpha: 0,
        stagger: 0.05,
        delay: 0.6,
      })
    }

    if (circle) {
      gsap.fromTo(
        circle,
        { width: w },
        {
          duration: 0.8,
          width: isPhone ? w * 1.5 : isDevice ? w * 2 : w * 3,
          yoyo: true,
          repeat: -1,
          ease: 'power3.inOut',
          delay: 1,
          repeatDelay: 1.2,
        },
      )
    }
  }
}

import store from '../../store'
import Highway from '@dogstudio/highway'
import { Emitter } from '../../core'
import { Smooth, Splits, Marquee } from '../../components'
import { bindAll, qs, qsa, formFocus, growTextarea } from '../../utils'
import { ScrollTrigger } from '../../vendor/ScrollTrigger'
import { Header } from '../../animations'
import gsap from 'gsap'

class Default extends Highway.Renderer {
  constructor(opt = {}) {
    super(opt)

    gsap.registerPlugin(ScrollTrigger)

    bindAll(this, 'animateIn')

    this.state = {
      ready: false,
      split: false,
    }
  }

  onEnter() {
    const { sniff, body } = store
    if (sniff.browser.isDevice) body.classList.add('is-device')
    store.page.el = this.wrap.lastElementChild
  }

  onEnterCompleted() {
    ScrollTrigger.refresh()
    if (store.flags.loaded) this.ready()
    this.on()
    this.preloader()
  }

  ready = () => {
    if (this.state.ready) return
    this.state.ready = true
    this.els()
    this.addComponents()
  }

  els() {
    this.dom = {
      images: qsa('img'),
      footer: qs('footer'),
    }
  }

  addComponents() {
    const { forms } = this.dom

    this.addSmooth()
    this.addScrollTrigger()
    this.addSplitText()
    this.addMarquee()
    this.loadImages()
  }

  loadImages() {
    const { images } = this.dom

    if (images.length === 0) {
      Smooth && Smooth.update()
    } else {
      Promise.all(
        images
          .filter((img) => !img.complete)
          .map(
            (img) =>
              new Promise((resolve) => {
                img.onload = img.onerror = resolve
              }),
          ),
      ).then(() => {
        Smooth && Smooth.update()
      })
    }
  }

  preloader() {
    const { ready } = this.state

    //if (!ready) Preloader()
  }

  run = ({ current }) => {
    this.current = current
    ScrollTrigger.update()
  }

  on() {
    Emitter.on('tick', this.run)
    Emitter.on('loaded', this.ready)
    Emitter.on('preloaded', this.animateIn)
    Emitter.on('resize', this.onResize)

    formFocus()
    growTextarea()
  }

  off() {
    Emitter.off('tick', this.run)
    Emitter.off('loaded', this.ready)
    Emitter.off('preloaded', this.animateIn)
    Emitter.off('resize', this.onResize)
  }

  addSmooth() {
    const items = qsa('[data-smooth-item]', this.el)
    store.SCROLL.setScrollBounds()
    store.flags.locked = false
    Smooth.init(items)
  }

  animateIn() {
    if (!store.LOADED) Header()

    store.LOADED = true
  }

  addSplitText() {
    this.spliText = new Splits()
  }

  addScrollTrigger() {
    const { body, page } = store

    ScrollTrigger.defaults({
      scroller: body,
    })

    ScrollTrigger.scrollerProxy(body, {
      scrollTop: () => {
        return this.current
      },

      getBoundingClientRect() {
        return { top: 0, left: 0, width: page.vw, height: page.vh }
      },
    })

    ScrollTrigger.refresh()
  }

  addMarquee() {
    const { footer } = this.dom

    if (footer) {
      this.marquee = new Marquee({
        els: qsa('.marquee-line'),
      })
    }
  }

  onResize = () => {
    setTimeout(() => {
      ScrollTrigger.update()
      ScrollTrigger.refresh()
    }, 100)
  }

  onLeave() {
    this.off()
    store.flags.locked = true
    this.marquee && this.marquee.destroy()
    gsap.set(store.body, { clearProps: 'all' })
  }

  onLeaveCompleted() {
    ScrollTrigger.getAll().forEach((inst) => inst.kill(true))
  }
}

export default Default

import store from '../store'
import { Emitter } from '../core'
import { qs, bounds, clamp } from '../utils'
import Preload from 'preload-it'

export default class Sprite {
  constructor(obj = {}) {
    const canvas = obj.canvas
    const container = obj.container
    const rect = bounds(container)
    const reference = qs('.smiley-asset')

    this.dom = {
      container: container,
      images: [],
      reference: reference,
    }

    this.canvas = {
      el: canvas,
      ctx: canvas.getContext('2d'),
      width: rect.width,
      height: rect.height,
      rect: rect,
    }

    this.state = {
      resize: false,
      loaded: false,
    }

    this.init()
  }

  loadImages() {
    const preload = Preload()
    const images = []

    for (let i = 0; i < 119; i++) {
      const img = `/images/sprite/${i}.png`
      images.push(img)
    }

    preload.fetch(images)
    preload.oncomplete = (items) => {
      items.forEach((item) => {
        const img = new Image()
        img.src = item.blobUrl
        this.dom.images.push(img)
      })

      this.state.loaded = true
    }
  }

  setup() {
    const { el, width, height, ctx, rect } = this.canvas
    const { reference } = this.dom
    const dpr = window.devicePixelRatio || 1
    const area = bounds(reference)
    const offset = area.top - rect.top
    const img = new Image()

    el.style.width = `${rect.width}px`
    el.style.height = `${rect.height}px`

    el.width = Math.floor(rect.width * dpr)
    el.height = Math.floor(rect.height * dpr)
    ctx.scale(dpr, dpr)

    img.onload = () => {
      ctx.clearRect(0, 0, width, height)
      ctx.drawImage(img, area.x, offset, area.width, area.height)
    }

    Object.assign(this.canvas, {
      area,
      offset,
    })

    img.src = '/images/sprite/0.png'
  }

  run = ({ current }) => {
    if (this.state.resize || !this.state.loaded) return

    this.scroll = current
    const { ctx, width, height, area, offset } = this.canvas
    const { images } = this.dom
    const { webgl } = store
    const val = clamp(webgl.smiley.sprite * 118, 0, 118)
    const index = Math.floor(val)
    const img = images[index]

    ctx.clearRect(0, 0, width, height)
    if (img) ctx.drawImage(img, area.x, offset, area.width, area.height)
  }

  on() {
    Emitter.on('tick', this.run)
    Emitter.on('resize', this.resize)
  }

  off() {
    Emitter.off('tick', this.run)
    Emitter.off('resize', this.resize)
  }

  resize = () => {
    const { container, reference } = this.dom
    const { el } = this.canvas
    this.state.resize = true

    const dpr = window.devicePixelRatio || 1
    const rect = bounds(container)
    const area = bounds(reference)
    const offset = area.top - rect.top

    el.style.width = `${rect.width}px`
    el.style.height = `${rect.height}px`
    el.width = Math.floor(rect.width * dpr)
    el.height = Math.floor(rect.height * dpr)
    this.canvas.width = rect.width
    this.canvas.height = rect.height
    this.canvas.bounds = rect
    this.canvas.ctx.scale(dpr, dpr)
    this.state.resize = false

    Object.assign(this.canvas, {
      area,
      offset,
    })
  }

  destroy() {
    this.off()
  }

  init() {
    this.loadImages()
    this.setup()
    this.on()
  }
}

import { qs, qsa } from '../utils'
import gsap from 'gsap'

export function Header() {
  const header = qs('header')
  const logo = qs('.logo', header)
  const links = qsa('.menu-item', header)

  gsap.set(header, { autoAlpha: 1 })

  gsap.from(logo, {
    duration: 0.5,
    autoAlpha: 0,
    y: '-100',
    ease: 'power2.out',
  })
  gsap.from(links, {
    duration: 0.8,
    autoAlpha: 0,
    y: '-100',
    delay: 0.1,
    stagger: 0.05,
    ease: 'power2.out',
  })
}

import store from '../store'
import { Emitter } from '../core'
import { bounds } from '../utils'
import gsap from 'gsap'

export default class Marquee {
  constructor(obj = {}) {
    const els = obj.els
    this.x = 0

    this.dom = {
      els,
    }

    this.setup()
    this.on()
  }

  setup = () => {
    const { els } = this.dom
    const rect = bounds(els[0])
    const total = els.length
    const width = rect.width
    const end = width * total

    this.settings = {
      rect,
      width,
      end,
      total,
      ease: 0.1,
    }
  }

  run = () => {
    const { sniff } = store
    const { els } = this.dom

    const x = sniff.browser.isDevice ? 1 : 3.5

    if (!els[0]) return

    const { width } = this.settings
    this.x = this.x < width ? this.x + x : 0
    gsap.set(els, { x: `${this.x * -1}px` })
  }

  on() {
    Emitter.on('tick', this.run)
    Emitter.on('resize', this.setup)
  }

  off() {
    Emitter.off('tick', this.run)
    Emitter.off('resize', this.setup)
  }

  destroy() {
    this.off
  }
}

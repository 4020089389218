import store from '../store'
import { qs, qsa } from '../utils'
import gsap from 'gsap'

export function Smile() {
  const smiley = qs('.smiley')

  if (smiley) {
    const smile = qs('.smiley-smile')

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: smiley,
        start: 'top +100%',
        end: 'top -80%',
        scrub: true,
        onLeave: () => {
          gsap.set(smile, { autoAlpha: 0 })
        },
        onEnterBack: () => {
          gsap.set(smile, { autoAlpha: 1 })
        },
      },
    })

    tl.fromTo(smile, { scale: 6 }, { scale: 0.5 })
  }
}

export function SpriteTl() {
  const { webgl } = store
  const smiley = qs('.smiley')

  if (smiley) {
    const smile = qs('.smiley-animation')
    const img = qs('.smiley-asset svg')

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: smiley,
        start: 'top -80%',
        end: 'center center',
        scrub: true,
        onLeave: () => {
          gsap.set(smile, { autoAlpha: 0 })
          gsap.set(img, { autoAlpha: 1 })
          webgl.smiley.planes.forEach((el) => {
            el.plane.program.uniforms.uAlpha.value = 1
          })
        },
        onEnterBack: () => {
          gsap.set(smile, { autoAlpha: 1 })
          gsap.set(img, { autoAlpha: 0 })
          webgl.smiley.planes.forEach((el) => {
            el.plane.program.uniforms.uAlpha.value = 0
          })
        },
        onUpdate: (self) => {
          store.webgl.smiley.sprite = self.progress
        },
      },
    })
  }
}

export function SpriteGL() {
  const { webgl } = store
  const smiley = qs('.smiley')
  const displacement = qs('feDisplacementMap')

  if (smiley) {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: smiley,
        start: 'center center',
        end: 'bottom bottom',
        scrub: true,
        onUpdate: (self) => {
          webgl.smiley.gooey = self.progress

          displacement.scale.baseVal = self.progress * 300
        },
      },
    })
  }
}

export function Partners() {
  const smiley = qs('.smiley')

  if (smiley) {
    const titles = qsa('.smiley-title', smiley)

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: smiley,
        start: 'top +100%',
        end: 'top -80%',
        scrub: true,
        onLeave: () => {
          gsap.to(titles, { autoAlpha: 0 })
        },
        onEnterBack: () => {
          gsap.to(titles, { autoAlpha: 1 })
        },
      },
    })

    tl.fromTo(titles[0], { scale: 4 }, { scale: 0.9 }, 0)
    tl.fromTo(titles[1], { scale: 0 }, { scale: 0.9 }, 0)
  }
}

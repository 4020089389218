/* Math
   Some helpfull math functions
   ========================================================================== */

export const lerp = (a, b, n) => {
  return a * (1 - n) + b * n
}

export const norm = (val, min, max) => {
  return (val - min) / (max - min)
}

export const clamp = (val, min, max) => {
  return Math.min(Math.max(val, min), max)
}

export const round = (n, p) => {
  var p = p !== undefined ? Math.pow(10, p) : 1000
  return Math.round(n * p) / p
}

export const map = (num, min1, max1, min2, max2, round = false) => {
  const num1 = (num - min1) / (max1 - min1)
  const num2 = num1 * (max2 - min2) + min2

  if (round) return Math.round(num2)

  return num2
}

import store from '../store'
import { qs, qsa } from '../utils'
import gsap from 'gsap'

export default class IndexGallery {
  constructor(obj = {}) {
    const el = obj.el

    this.dom = {
      el,
      list: qs('.work-list'),
      title: qs('.index-title'),
      open: qs('.index-open'),
      close: qs('.index-close'),
      bg: qsa('.bg-block'),
      workLetters: qsa('.item-letter:not(.-hidden)'),
      workTitles: qsa('.item-title'),
      workImgs: qsa('.item-img'),
      slider: qs('.slider'),
      webgl: qs('.gl-dom'),
    }

    this.state = {
      open: false,
    }

    this.init()
  }

  openIndex = () => {
    const { el, bg, workLetters, workTitles, workImgs, title, slider, webgl } =
      this.dom

    gsap.set(bg, { backgroundColor: '#262523' })
    gsap.set(el, { autoAlpha: 1 })
    gsap.to(slider, { duration: 0.5, autoAlpha: 0 })
    gsap.to('.gl-dom', { duration: 0.5, autoAlpha: 0 })
    gsap.to(webgl, { duration: 0.5, autoAlpha: 0 })
    gsap.fromTo(bg, { scaleY: 0 }, { duration: 0.6, scaleY: 1, stagger: 0.05 })

    gsap.from(title, { duration: 1, y: 0, delay: 0.5, autoAlpha: 0 })
    gsap.from(workTitles, {
      duration: 0.6,
      delay: 0.5,
      y: 20,
      autoAlpha: 0,
      stagger: 0.01,
    })

    gsap.from(workLetters, {
      duration: 0.6,
      delay: 0.5,
      y: 20,
      autoAlpha: 0,
      stagger: 0.01,
    })
    gsap.from(workImgs, {
      duration: 0.6,
      delay: 0.5,
      x: 20,
      autoAlpha: 0,
      stagger: 0.05,
      onComplete: () => {
        gsap.set(workTitles, { clearProps: 'all' })
        gsap.set(workImgs, { clearProps: 'all' })
        gsap.set(workLetters, { clearProps: 'all' })
      },
    })

    gsap.set(store.body, { overflow: 'hidden' })
  }

  closeIndex = () => {
    const { el, bg, slider, webgl } = this.dom

    gsap.fromTo(
      bg,
      { scaleY: 1 },
      {
        duration: 0.6,
        scaleY: 0,
        stagger: 0.05,
        onComplete: () => {
          gsap.set(bg, { backgroundColor: '#262523' })
          gsap.to(slider, { duration: 0.5, autoAlpha: 1 })
          gsap.to('.gl-dom', { duration: 0.5, autoAlpha: 1 })
          gsap.to(webgl, { duration: 0.5, autoAlpha: 1 })
        },
      },
    )
    gsap.to(el, { duration: 0.5, autoAlpha: 0 })

    gsap.set(store.body, { clearProps: 'all' })
  }

  selectWork = (e) => {
    const { list } = this.dom

    const target = e.target
    const parent = target.closest('.work-item')
    const project = target.dataset.project
    const image = target.dataset.img

    list.classList.add('-hover')

    if (project) {
      const img = qs(`[data-img="${project}"]`)
      img.classList.add('-hover')
      parent.classList.add('-hover')
    } else {
      const proy = qs(`[data-project="${image}"]`)
      const letter = proy.closest('.work-item')
      proy.classList.add('-hover')
      letter.classList.add('-hover')
    }
  }

  leaveWork = (e) => {
    const { list } = this.dom

    const target = e.target
    const parent = target.closest('.work-item')
    const project = target.dataset.project
    const image = target.dataset.img

    list.classList.remove('-hover')

    if (project) {
      const img = qs(`[data-img="${project}"]`)
      img.classList.remove('-hover')
      parent.classList.remove('-hover')
    } else {
      const proy = qs(`[data-project="${image}"]`)
      const letter = proy.closest('.work-item')
      proy.classList.remove('-hover')
      letter.classList.remove('-hover')
    }
  }

  on() {
    const { open, close, workTitles, workImgs } = this.dom

    open.addEventListener('click', this.openIndex)
    close.addEventListener('click', this.closeIndex)

    workTitles.forEach((title) => {
      title.addEventListener('mouseenter', this.selectWork)
      title.addEventListener('mouseleave', this.leaveWork)
    })

    workImgs.forEach((title) => {
      title.addEventListener('mouseenter', this.selectWork)
      title.addEventListener('mouseleave', this.leaveWork)
    })
  }

  off() {
    const { open, close } = this.dom
    open.removeEventListener('click', this.openIndex)
    close.removeEventListener('click', this.closeIndex)
  }

  destroy() {
    this.off()
    this.dom = null
  }

  init() {
    this.on()
  }
}

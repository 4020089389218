import store from '../store'
import { Emitter } from '.'
import debounce from 'lodash.debounce'
import {
  Sniffer,
  getWindowSizes,
  getViewport,
  setViewportCSSVar,
} from '../utils'

export default class GlobalResize {
  constructor() {
    this.state = {
      resized: false,
    }

    this.init()
  }

  resize = () => {
    const { sniff, page } = store
    const { browser } = sniff
    const { width, height } = getViewport()

    if (browser.isDevice && window.innerWidth === page.vw && this.state.resized)
      return

    Sniffer.update()
    this.reload()
    store.flags.resize = true
    store.page.vw = width
    store.page.vh = height
    store.sniff.breakpoints = getWindowSizes()
    store.sniff.browser = Sniffer.sniff
    store.sniff.orientation = width < height ? 'portrait' : 'landscape'
    store.SCROLL.setScrollBounds()
    setViewportCSSVar()

    this.add()

    Emitter.emit('resize')
    this.state.resized = true
    store.flags.resize = false
  }

  reload() {
    const { sniff } = store
    const { browser } = sniff

    if (
      (!browser.isDevice && Sniffer.isDevice) ||
      (browser.isDevice && !Sniffer.isDevice)
    )
      location.reload()
  }

  add() {
    const { sniff, body, page } = store
    const { browser } = sniff

    page.vw < page.vh
      ? body.classList.add('is-portrait')
      : body.classList.remove('is-portrait')

    if (browser.isDevice) body.classList.add('is-device')
  }

  on() {
    window.addEventListener('resize', debounce(this.resize, 200))
  }

  init() {
    this.on()
  }
}

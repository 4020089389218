import store from '../store'
import { Page } from './renders'
import { Fade } from './transitions'
import Highway from '@dogstudio/highway'
import { Menu } from '../components'
//import { Stage } from '../webgl'

import SplitText from '../vendor/SplitText'
import { qs, qsa } from '../utils'
import gsap from 'gsap'

export default class H {
  constructor() {
    const links = qsa('.link-title')

    store.H = new Highway.Core({
      renderers: {
        page: Page,
      },
      transitions: {
        default: Fade,
        contextual: {
          fade: Fade,
        },
      },
    })

    // this.webgl = new Stage({
    //   el: store.body,
    // })

    links.forEach((link) => {
      new SplitText(link, {
        type: 'chars',
        charsClass: 'char-0',
      })
    })

    const menu = new Menu({
      desktopMenu: qs('header'),
      mobileMenu: qs('.menuMobile'),
      footer: qs('footer'),
      url: store.H.location.href,
      open: qs('header .burger'),
      close: qs('.menuMobile .close'),
    })

    store.H.on('NAVIGATE_END', (e) => {
      menu.update(store.H.location.href)
    })

    store.H.on('NAVIGATE_OUT', (e) => {
      menu.closeMenu()
      menu.playTransition()
    })
  }
}

import store from '../../store'
import Default from './default'
import { Emitter } from '../../core'
import {
  OneFlip,
  Sticky,
  Cursor,
  Slider,
  domOGL,
  Marquee,
  Particles,
  IndexGallery,
  boxGL,
  Sprite,
} from '../../components'
import {
  Flipped,
  LargeTitle,
  LargeTitleIn,
  ProjectIn,
  ContactIn,
  CapableIn,
  ElvaIn,
  Cases,
  HeroEmojis,
  SwirlModal,
  BgScroll,
  Smile,
  SpriteTl,
  SpriteGL,
  Partners,
} from '../../animations'
import { qs, qsa } from '../../utils'
import gsap from 'gsap'

export default class Page extends Default {
  constructor(opt = {}) {
    super(opt)
    this.slug = 'page'
  }

  onEnter() {
    super.onEnter()
  }

  onEnterCompleted() {
    super.onEnterCompleted()
  }

  els() {
    super.els()

    const slider = {
      circular: qs('.slider.-circular'),
      horizontal: qs('.slider.-horizontal'),
      el: qs('.slider-container'),
      els: qsa('.slider-item'),
      labels: qsa('.slider-label'),
    }

    const team = {
      bgs: qsa('.team-inner'),
      els: qsa('.team-item'),
      container: qs('.team-bg'),
    }

    const forms = {
      els: qsa('form'),
      marqueeDefault: qs('.marquee-default'),
      marqueeSuccess: qs('.marquee-success'),
    }

    Object.assign(this.dom, {
      home: qs('.is-home'),
      flipped: qs('.oneSection'),
      sticky: qsa('[data-sticky]'),
      planes: qsa('[data-plane]'),
      slider: slider,
      team: team,
      forms: forms,
    })
  }

  addComponents() {
    super.addComponents()

    const { forms } = this.dom
    const { ready } = this.state

    setTimeout(() => {
      this.addFlip()
      this.addCursor()
      this.addSlider()
      this.addTeam()
      this.addIndex()
      this.addParticles()
      this.addBoxes()
      this.addSprite()
      this.addAnimations()
      this.addSticky()
      this.addDomGL()
    }, 100)

    if (forms.els[0]) {
      forms.els.forEach((form) => {
        form.addEventListener('submit', (e) => {
          this.handleSubmit(e, form)
        })

        form.addEventListener('click', (e) => {
          this.handleError(e, form)
        })
      })
    }
  }

  animateIn() {
    super.animateIn()

    setTimeout(() => {
      ProjectIn()
    }, 100)
    HeroEmojis()
    ContactIn()
    LargeTitleIn()
    CapableIn()
    ElvaIn()
  }

  addSticky() {
    const { sticky } = this.dom

    this.stickies = []

    if (sticky[0]) {
      sticky.forEach((el, i) => {
        const st = new Sticky({ el })
        st.on()
        this.stickies.push(st)
      })
    }
  }

  addFlip() {
    const { flipped } = this.dom
    if (flipped) this.flip = new OneFlip()
  }

  addCursor() {
    const { sniff } = store
    const { home } = this.dom
    const cursor = qs('.cursor-dark')
    if (!home) return

    if (sniff.browser.isDesktop) {
      this.cursor = new Cursor({
        container: store.page.el,
        markers: [
          {
            el: qs('.featuredProject-asset'),
            icon: cursor
              ? '/images/circle-dark.png'
              : '/images/circle-light.png',
            skin: cursor ? 'dark' : 'light',
            size: 48,
          },
        ],
      })
    }
  }

  addSlider() {
    const { slider } = this.dom
    const { circular, horizontal, el, els, labels } = slider

    if (circular) {
      this.circular = new Slider({
        container: circular,
        el: el,
        els: els,
        labels: labels,
        template: 'circular',
      })
    }

    if (horizontal) {
      this.horizontal = new Slider({
        container: horizontal,
        el: el,
        els: els,
        template: 'horizontal',
      })
    }
  }

  addTeam() {
    const { team } = this.dom
    const { els, bgs, container } = team

    if (!els[0]) return

    const onEnter = (el) => {
      const target = el.target
      const data = target.dataset.service
      const bg = qs(`.${data}`)
      const assets = qsa('.team-asset', bg)
      bgs.forEach((item) => item.classList.remove('-active'))
      bg.classList.add('-active')
      container.classList.add('-color')
      gsap.fromTo(
        assets,
        { autoAlpha: 0, y: 10 },
        { duration: 0.35, y: 0, autoAlpha: 1, stagger: 0.03 },
      )
    }

    const onLeave = () => {
      container.classList.remove('-color')
    }

    els.forEach((item) => {
      item.addEventListener('mouseenter', onEnter)
      item.addEventListener('mouseleave', onLeave)
    })
  }

  addDomGL() {
    const { sniff } = store
    const { planes } = this.dom

    if (!planes[0]) return

    if (sniff.browser.isDesktop) {
      this.domGL = new domOGL({
        container: store.page.el,
        textures: planes,
      })
    }
  }

  addIndex() {
    const el = qs('.work-index')

    if (el)
      this.indexGallery = new IndexGallery({
        el: el,
      })
  }

  addParticles() {
    const { flipped } = this.dom

    if (flipped) {
      this.particles = new Particles({
        container: flipped,
      })
    }
  }

  addBoxes() {
    const oneSection = qs('.oneSection')

    if (oneSection) {
      this.boxes = new boxGL({
        container: oneSection,
      })
    }
  }

  addSprite() {
    const container = qs('.smiley-content')

    if (container) {
      this.sprite = new Sprite({
        container: container,
        canvas: qs('.smiley-frames'),
      })
    }
  }

  addMarquee() {
    super.addMarquee()

    const { forms } = this.dom
    const { marqueeDefault, marqueeSuccess } = forms

    if (marqueeDefault) {
      this.submit = new Marquee({
        els: qsa('.marquee-item', marqueeDefault),
      })
    }

    if (marqueeSuccess) {
      this.success = new Marquee({
        els: qsa('.marquee-item', marqueeSuccess),
      })
    }
  }

  addAnimations() {
    const { LOADED } = store

    if (LOADED) {
      setTimeout(() => {
        HeroEmojis()
        ContactIn()
        LargeTitleIn()
        ProjectIn()
        CapableIn()
        ElvaIn()
      }, 10)
    }
    LargeTitle()
    SwirlModal()
    Cases()
    BgScroll()
    Smile()
    SpriteTl()
    SpriteGL()
    Partners()
  }

  handleSubmit = (e, form) => {
    e.preventDefault()

    const { forms } = this.dom
    const { marqueeDefault, marqueeSuccess } = forms
    const formData = new FormData(form)

    fetch(form.getAttribute('action'), {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        gsap.set(marqueeDefault, { autoAlpha: 0 })
        gsap.set(marqueeSuccess, { autoAlpha: 1 })
        form.reset()
      })
      .catch((error) => console.log(error))
  }

  handleError = (e, form) => {
    const required = qsa('[required]', form)

    required.forEach((item) => {
      const value = item.value.length
      const parent = item.parentNode

      if (value < 1) {
        parent.classList.add('-error')
      } else {
        parent.classList.remove('-error')
      }
    })
  }

  on() {
    super.on()
    Emitter.on('flip', (e) => Flipped(e))
  }

  off() {
    super.off()
    Emitter.off('flip', (e) => Flipped(e))
  }

  onLeave() {
    super.onLeave()
    this.off()
    this.flip && this.flip.destroy()
    this.horizontal && this.horizontal.destroy()
    this.circular && this.circular.destroy()
    this.submit && this.submit.destroy()
    this.success && this.success.destroy()
    this.particles && this.particles.destroy()
    this.indexGallery && this.indexGallery.destroy()
    this.boxes && this.boxes.destroy()
    this.sprite && this.sprite.destroy()
    this.stickies.forEach((item) => {
      item && item.destroy()
    })

    this.dom = {}
  }

  onLeaveCompleted() {
    super.onLeaveCompleted()
    this.domGL && this.domGL.destroy()
  }
}

import store from '../store'
import { qs, qsa } from '../utils'
import gsap from 'gsap'

export function ProjectIn() {
  const hero = qs('.caseHero')
  const { webgl } = store
  const { images } = webgl
  const plane = store.webgl.images.planes[0]

  if (hero) {
    const lines = qsa('.line-0', hero)
    const p = qs('.p', hero)
    const link = qs('.caseHero-url', hero)
    const image = plane
      ? plane.plane.program.uniforms.uReveal
      : qs('.asset-crop', hero)

    gsap.set(hero, { autoAlpha: 1 })

    lines.forEach((line) => {
      const chars = qsa('.char-1', line)

      if (chars[0]) {
        gsap.from(chars, {
          duration: 0.8,
          y: '-101%',
          delay: 0.5,
          stagger: 0.05,
        })
      }
    })

    if (p) gsap.from(p, { duration: 0.5, autoAlpha: 0, y: 30, delay: 0.5 })
    if (link)
      gsap.from(link, { duration: 0.5, autoAlpha: 0, y: 30, delay: 0.7 })
    if (image) {
      if (plane) {
        gsap.fromTo(
          image,
          {
            value: 1,
          },
          {
            duration: 2,
            value: 0,
            ease: 'rough({ template: none.out, strength: 1, points: 20, taper: none, randomize: true, clamp: false})',
          },
        )
      } else {
        gsap.from(image, {
          duration: 1,
          delay: 0.5,
          autoAlpha: 0,
        })
      }
    }
  }
}

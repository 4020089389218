import store from '../store'
import { Emitter } from '../core'
import { bounds, updateSize, updateX, updateY } from '../utils'
import { Renderer, Camera, Transform, Plane, Program, Mesh, Texture } from 'ogl'
import vertex from '../glsl/shaders/videoVertex.glsl'
import fragment from '../glsl/shaders/videoFragment.glsl'
import gsap from 'gsap'

const vertex100 =
  /* glsl */ `
` + vertex

const vertex300 =
  /* glsl */ `#version 300 es
    #define attribute in
    #define varying out
` + vertex

const fragment100 =
  /* glsl */ `#extension GL_OES_standard_derivatives : enable
    precision highp float;
` + fragment

const fragment300 =
  /* glsl */ `#version 300 es
    precision highp float;
    #define varying in
    #define texture2D texture
    #define gl_FragColor FragColor
    out vec4 FragColor;
` + fragment

export default class Background {
  constructor(obj = {}) {
    const container = obj.container
    const texture = obj.texture

    this.dom = {
      container: container,
      texture: texture,
      planes: [],
      total: 0,
    }

    this.state = {
      isResizing: false,
    }

    this.time = 0
    this.init()
  }

  setup() {
    const { container } = this.dom
    const { page } = store
    const renderer = new Renderer({ dpr: 2, alpha: true, depth: false })
    const gl = renderer.gl
    const camera = new Camera(gl, { fov: 35 })
    const scene = new Transform()
    const aspectRatio = page.vw / page.vh

    gl.clearColor(0, 0, 0, 0)
    camera.position.set(0, 0, 5)
    camera.lookAt([0, 0, 0])
    camera.perspective({
      aspect: aspectRatio,
    })

    renderer.setSize(page.vw, page.vh)

    this.ogl = {
      renderer: renderer,
      gl: gl,
      camera: camera,
      texture: false,
      scene: scene,
      aspectRatio: aspectRatio,
    }

    gl.canvas.classList.add('gl-bg')
    container.appendChild(gl.canvas)
  }

  set() {
    const { container } = this.dom
    const { camera, scene, aspectRatio } = this.ogl
    const data = this.getPlane()
    const rectTexture = bounds(container)
    const scale = updateSize(rectTexture, camera, aspectRatio)
    const posX = updateX(rectTexture.left, scale)
    const posY = updateY(rectTexture.top, scale, 0)
    const { size } = scale

    data.mesh.position.set(posX.x, posY.y, 1)
    data.mesh.scale.set(size.x, size.y, 1)
    data.mesh.setParent(scene)

    Object.assign(this.ogl, {
      data,
    })
  }

  getPlane() {
    const { texture } = this.dom
    const { gl, renderer } = this.ogl
    const plane = {}

    const tex = new Texture(gl, {
      generateMipmaps: false,
      minFilter: gl.LINEAR,
    })

    tex.image = texture

    const geometry = new Plane(gl, {
      widthSegments: 32,
      heightSegments: 32,
    })

    const program = new Program(gl, {
      vertex: renderer.isWebgl2 ? vertex300 : vertex100,
      fragment: renderer.isWebgl2 ? fragment300 : fragment100,
      transparent: true,
      uniforms: {
        uTexture: { value: tex },
        uTime: { value: 0 },
        uAlpha: { value: 0 },
        uProgress: { value: 0 },
        uPower: { value: 0 },
        uOut: { value: true },
      },
    })

    const mesh = new Mesh(gl, {
      geometry: geometry,
      program: program,
    })

    plane.geometry = geometry
    plane.program = program
    plane.mesh = mesh
    plane.texture = tex

    return plane
  }

  update = () => {
    const { container } = this.dom
    const { camera, aspectRatio, data } = this.ogl
    const rectTexture = bounds(container)
    const scale = updateSize(rectTexture, camera, aspectRatio)
    const posX = updateX(0, scale)
    const posY = updateY(0, scale, 0)
    const { size } = scale
    data.mesh.position.set(posX.x, posY.y, 1)
    data.mesh.scale.set(size.x * 2, size.y, 1)
  }

  run = () => {
    const { renderer, camera, scene, data } = this.ogl
    const { texture } = this.dom

    this.time += 1 / 60

    if (this.state.isResizing) return

    if (texture.readyState >= texture.HAVE_ENOUGH_DATA) {
      if (!data.texture.image) data.texture.image = texture
      data.texture.needsUpdate = true
      renderer.render({ scene, camera })
    }
  }

  resize = () => {
    const { page } = store
    this.state.isResizing = true
    const aspectRatio = page.vw / page.vh

    this.ogl.camera.perspective({
      aspect: aspectRatio,
    })

    this.ogl.renderer.setSize(page.vw, page.vh)
    this.state.isResizing = false
  }

  on() {
    Emitter.on('tick', this.run)
    Emitter.on('smooth:resize', this.update)
    Emitter.on('resize', this.resize)
  }

  off() {
    Emitter.off('tick', this.run)
    Emitter.off('smooth:resize', this.update)
    Emitter.off('resize', this.resize)
  }

  init() {
    this.setup()
    this.set()
    this.on()
  }
}

import store from '../store'
import { Program, Mesh, Texture, Text, Geometry, Plane, Vec2 } from 'ogl'
import vertex from '../glsl/shaders/textVertex.glsl'
import fragment from '../glsl/shaders/textFragment.glsl'
import twirlV from '../glsl/shaders/twirlVertex.glsl'
import twirlF from '../glsl/shaders/twirlFragment.glsl'

const vertex100 =
  /* glsl */ `
` + vertex

const vertex300 =
  /* glsl */ `#version 300 es
    #define attribute in
    #define varying out
` + vertex

const fragment100 =
  /* glsl */ `#extension GL_OES_standard_derivatives : enable
    precision highp float;
` + fragment

const fragment300 =
  /* glsl */ `#version 300 es
    precision highp float;
    #define varying in
    #define texture2D texture
    #define gl_FragColor FragColor
    out vec4 FragColor;
` + fragment

export default class TextGl {
  constructor(obj = {}) {
    const gl = obj.gl
    const texture = obj.texture
    const renderer = obj.renderer
    const target = obj.target
    const txt = obj.text

    this.plane = {}

    this.ogl = {
      gl: gl,
      texture: texture,
      renderer: renderer,
      target: target,
      txt: txt,
    }

    this.font = null

    this.mouse = new Vec2(0, 0)

    this.setup()
    this.textMesh()
  }

  setup() {
    const { page } = store
    const { gl, target } = this.ogl

    const geometry = new Plane(gl, {
      widthSegments: 32,
      heightSegments: 32,
    })

    const program = new Program(gl, {
      vertex: twirlV,
      fragment: twirlF,
      transparent: true,
      uniforms: {
        tMap: { value: target.texture },
        uMouse: { value: new Vec2(0.0, 0.0) },
        uRes: { value: new Vec2(page.vw, page.vh) },
        uAngle: { value: 0.8 },
        uRadius: { value: 0.2 },
        uPosY: { value: 0 },
      },
    })

    const mesh = new Mesh(gl, {
      geometry: geometry,
      program: program,
    })

    this.plane.geometry = geometry
    this.plane.program = program
    this.plane.mesh = mesh
    this.plane.status = false
  }

  textMesh() {
    const { gl, renderer } = this.ogl

    const texture = new Texture(gl, {
      generateMipmaps: false,
    })

    const img = new Image()
    img.onload = () => (texture.image = img)
    img.src = '/images/atlas.png'

    const program = new Program(gl, {
      vertex: renderer.isWebgl2 ? vertex300 : vertex100,
      fragment: renderer.isWebgl2 ? fragment300 : fragment100,
      uniforms: {
        tMap: { value: texture },
        uMouse: { value: this.mouse },
      },
      transparent: true,
      cullFace: null,
      depthWrite: false,
    })

    Object.assign(this.ogl, {
      program,
    })

    this.loadText(program)
  }

  updateTexture() {
    const { gl, txt, program } = this.ogl
    const { page } = store
    const w = (page.vw * 4) / 1440
    const s = (page.vw * 0.32) / 1440

    const text = new Text({
      font: this.font,
      text: txt.toUpperCase(),
      width: w,
      align: 'center',
      letterSpacing: -0.06,
      size: s,
      lineHeight: 1,
    })

    const geometry = new Geometry(gl, {
      position: { size: 3, data: text.buffers.position },
      uv: { size: 2, data: text.buffers.uv },
      id: { size: 1, data: text.buffers.id },
      index: { data: text.buffers.index },
    })

    const mesh = new Mesh(gl, { geometry, program })
    mesh.position.y = text.height * 0.5
    this.plane.text = text
    this.plane.textProgram = program
    this.plane.textMesh = mesh
    this.plane.textGeometry = geometry
    return this.plane
  }

  async loadText(program) {
    const { page } = store
    const { gl, txt } = this.ogl
    const font = await (
      await fetch('/fonts/basis-grotesque-medium-pro.json')
    ).json()

    this.font = font

    const w = (page.vw * 4) / 1440
    const s = (page.vw * 0.32) / 1440
    const text = new Text({
      font,
      text: txt.toUpperCase(),
      width: w,
      align: 'center',
      letterSpacing: -0.06,
      size: s,
      lineHeight: 1.15,
    })

    const geometry = new Geometry(gl, {
      position: { size: 3, data: text.buffers.position },
      uv: { size: 2, data: text.buffers.uv },
      id: { size: 1, data: text.buffers.id },
      index: { data: text.buffers.index },
    })

    const mesh = new Mesh(gl, { geometry, program })
    mesh.position.y = text.height * 0.5
    this.plane.text = text
    this.plane.textProgram = program
    this.plane.textMesh = mesh
    this.plane.textGeometry = geometry
    return this.plane
  }
}

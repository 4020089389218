import store from '../store'
import { Emitter } from '../core'
import { lerp, bounds, qsa, updateX } from '../utils'
import gsap from 'gsap'

const map = (num, min1, max1, min2, max2, round = false) => {
  const num1 = (num - min1) / (max1 - min1)
  const num2 = num1 * (max2 - min2) + min2

  if (round) return Math.round(num2)

  return num2
}

export default class Slider {
  constructor(obj = {}) {
    store.SLIDER = {
      lerped: 0,
    }

    store.SLIDER.x = 0

    this.dom = {
      container: obj.container,
      el: obj.el,
      els: obj.els,
      labels: obj.labels,
      template: obj.template,
    }

    this.state = {
      dragStart: false,
      dragging: false,
      x: 0,
      start: 0,
      current: 0,
      lerped: 0,
      end: 0,
      direction: '',
      click: true,
      diff: 0,
      isResizing: false,
    }

    this.init()
  }

  setup() {
    if (!this.dom.container) return

    const { el, els, template } = this.dom
    const { page, webgl } = store
    const boundings = bounds(els[0])
    const grid = page.vw / 18

    this.data = []
    this.state.total = els.length
    this.top = bounds(el)

    els.forEach((item, i) => {
      const plane = webgl.slider.planes[i]
      const index = i + 1
      const rect = bounds(item)
      const width = boundings.width
      const container = boundings.width * this.state.total
      const circular = template == 'circular' ? true : false
      const wrap = gsap.utils.wrap(-width, container - width)

      const obj = {
        index: index,
        el: item,
        wrap: wrap,
        circular: circular,
        width: width,
        container: container,
        grid,
        boundings,
      }

      if (plane) plane.rect = rect

      this.data.push(obj)
    })
  }

  run = ({ mouse, current }) => {
    const { lerped, x, dragging } = this.state

    if (this.state.isResizing) return

    this.state.diff = 0
    if (dragging) this.state.click = false
    if (dragging) this.state.diff = (x - lerped) * 0.005
    this.state.x = x
    this.state.current = mouse.x
    this.state.scroll = current
    this.state.lerped = lerp(lerped, x, 0.1)
    this.state.click = true
    store.SLIDER.lerped = lerped

    this.loopSlider()
  }

  mouseDown = (e) => {
    const { el } = this.dom
    this.state.dragStart = true
    const mouseX = e.clientX || e.touches[0].clientX
    this.state.start = mouseX
    el.classList.add('-dragging')
  }

  mouseMove = (e) => {
    if (!this.state.dragStart) return
    const { els } = this.dom
    this.state.x += (this.state.current - this.state.start) * 2.5
    this.state.start = this.state.current
    this.state.dragging = true
    store.webgl.slider.dragging = true

    for (let i = 0; i < els.length; i++) {
      this.dom.els[i].style.pointerEvents = 'none'
    }
  }

  mouseUp = (e) => {
    e.stopPropagation()
    const mouseX = e.clientX || e.changedTouches[0].clientX
    const { x, dragging, start } = this.state
    const { els, el } = this.dom
    this.state.end = mouseX
    this.state.dragStart = false

    if (dragging) {
      this.state.direction = x > mouseX ? 'left' : 'right'
    }

    el.classList.remove('-dragging')

    setTimeout(() => {
      if (start !== mouseX && dragging) this.onCheck()

      for (let i = 0; i < els.length; i++) {
        this.dom.els[i].style.pointerEvents = ''
      }
      this.state.dragging = false
      store.webgl.slider.dragging = false
    }, 500)
  }

  loopSlider() {
    const { lerped } = this.state
    const { webgl, sniff } = store
    const { top } = this.top

    this.data.forEach((item, i) => {
      const { index, wrap, el, circular, width, container, grid } = item

      const plane = sniff.browser.isDesktop
        ? webgl.slider.planes[i].plane
        : null
      const scale = sniff.browser.isDesktop
        ? webgl.slider.planes[i].scale
        : null

      const offset = sniff.breakpoints.M_UP ? grid * 1.5 : width / 1.17
      const offset2 = sniff.breakpoints.M_UP ? grid * 12 : grid * 33
      const offset3 = sniff.breakpoints.M_UP ? 240 : 120
      const cx = index * width + lerped - offset
      const cy = Math.sin(((cx + offset2) / container) * -Math.PI)
      const hx = i * width + lerped
      const posX = circular ? cx : hx
      const posY = circular ? cy * offset3 + offset3 : 0
      const wrapX = wrap(parseInt(posX))

      if (plane) plane.mesh.position[0] = updateX(posX + grid, scale).x
      if (plane) plane.program.uniforms.uDiff.value = this.state.diff

      const rotate = map(
        wrapX - width + offset,
        -container,
        container,
        -Math.PI * 15,
        Math.PI * 15,
      )

      const rotZ = circular ? rotate : 0

      if (plane) webgl.slider.planes[i].posZ = rotZ

      gsap.set(el, {
        x: posX,
        y: posY,
        rotate: rotZ,
        modifiers: {
          x: (x) => {
            const cosx = wrap(parseInt(x))
            if (plane) plane.mesh.position[0] = updateX(cosx + grid, scale).x
            return `${cosx}px`
          },
          y: (y) => {
            const wrapY = Math.sin(((wrapX + offset2) / container) * -Math.PI)
            const pos = circular ? wrapY * offset3 + offset3 : 0

            if (plane) webgl.slider.planes[i].posY = pos + top
            return `${pos}px`
          },
        },
      })
    })
  }

  onCheck() {
    const { width } = this.data[0].boundings
    const { lerped, total } = this.state
    const index = Math.round(lerped / width)
    const item = width * Math.abs(index)
    let i = Math.abs(index)
    let nr = i % total

    if (this.state.x < 0) {
      i = nr
      this.state.x = -item
    } else {
      this.state.x = item
      i = nr == 0 ? 0 : total - nr
    }

    this.updateLabel(i)
  }

  openLink = (e) => {
    const { click } = this.state

    if (!click) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    const target = e.target
    const origin = window.location.origin
    const href = target.dataset.href
    const url = `${origin + href}`

    if (href) store.H.redirect(url, 'fade')
  }

  updateLabel(index) {
    const { labels } = this.dom
    if (!labels) return
    const current = labels[index]
    const p = qsa('p', current)

    gsap.to(labels, { duration: 0.25, autoAlpha: 0 })
    gsap.set(current, { autoAlpha: 1, delay: 0.25 })
    gsap.fromTo(
      p,
      { autoAlpha: 0, y: 10 },
      {
        duration: 0.3,
        delay: 0.25,
        y: 0,
        autoAlpha: 1,
        stagger: 0.05,
      },
    )
  }

  on() {
    if (!this.dom.container) return
    const { sniff } = store
    const { container } = this.dom
    container.addEventListener('touchstart', this.mouseDown)
    container.addEventListener('touchmove', this.mouseMove)
    container.addEventListener('touchend', this.mouseUp)
    container.addEventListener('mousedown', this.mouseDown)
    container.addEventListener('mousemove', this.mouseMove)
    container.addEventListener('mouseleave', this.mouseUp)
    container.addEventListener('mouseup', this.mouseUp, false)
    container.addEventListener('click', this.openLink)

    Emitter.on('tick', this.run)

    if (sniff.browser.isDevice) {
      Emitter.on('resize', this.resize)
    } else {
      Emitter.on('smooth:resize', this.resize)
    }
  }

  off() {
    if (!this.dom.container) return
    const { sniff } = store
    const { container } = this.dom
    container.removeEventListener('touchstart', this.mouseDown)
    container.removeEventListener('touchmove', this.mouseMove)
    container.removeEventListener('touchend', this.mouseUp)
    container.removeEventListener('mousedown', this.mouseDown)
    container.removeEventListener('mousemove', this.mouseMove)
    container.removeEventListener('mouseleave', this.mouseUp)
    container.removeEventListener('mouseup', this.mouseUp)

    Emitter.off('tick', this.run)

    if (sniff.browser.isDevice) {
      Emitter.off('resize', this.resize)
    } else {
      Emitter.off('smooth:resize', this.resize)
    }
  }

  resize = () => {
    this.state.isResizing = true
    this.setup()
    this.state.isResizing = false
  }

  destroy() {
    this.off()
    this.data = []
  }

  init() {
    this.on()
    this.setup()
  }
}

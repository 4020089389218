import store from '../store'
import { Emitter } from '../core'
import { qsa } from '../utils'
import WheelIndicator from 'wheel-indicator'
import gsap from 'gsap'

export default class OneFlip {
  constructor() {
    this.sections = qsa('.-one')

    this.state = {
      next: 0,
      prev: 0,
      current: 0,
      animating: false,
      touchStart: 0,
      touchEnd: 0,
      locked: false,
      scroll: 0,
    }

    this.state.total = this.sections.length - 1
    gsap.set('.oneSection-bg', { scale: 0, rotate: -45 })

    gsap.set(store.body, { overflow: 'hidden' })

    this.init()
  }

  getMousePosition(e) {
    var mousePosition
    if (e.targetTouches) {
      if (e.targetTouches[0]) {
        mousePosition = [e.targetTouches[0].clientX, e.targetTouches[0].clientY]
      } else if (e.changedTouches[0]) {
        // handling touch end event
        mousePosition = [
          e.changedTouches[0].clientX,
          e.changedTouches[0].clientY,
        ]
      } else {
        // fallback
        mousePosition = [e.clientX, e.clientY]
      }
    } else {
      mousePosition = [e.clientX, e.clientY]
    }

    return mousePosition
  }

  updateState() {
    this.state.prev = 0
    this.state.next = 0
  }

  mouseDown = (e) => {
    this.state.touchStart = this.getMousePosition(e)[1]
    this.state.current = this.state.touchStart
  }

  mouseMove = (e) => {
    this.state.current = this.getMousePosition(e)[1]
  }

  mouseUp = () => {
    if (this.state.touchStart == this.state.current) return

    const start = this.state.touchStart
    const end = this.state.current
    const total = start - end
    this.state.touchEnd = end
    this.touchDir = start - end > 0 ? 'down' : 'up'

    // Touch is also triggered on click
    if ((total < 0 && total > -60) || (total > 0 && total < 60)) return

    this.onScroll()
    this.moveUp()
  }

  onScroll(e) {
    if (this.state.animating || this.state.locked) return

    const { next, total } = this.state
    const direction = e ? e.direction : this.touchDir

    this.state.next =
      direction == 'up'
        ? next <= 0
          ? 0
          : next - 1
        : next < total
        ? next + 1
        : total

    if (this.state.prev === this.state.next) return

    this.state.animating = true

    Emitter.emit('flip', {
      index: this.state.next,
      prev: this.state.prev,
      direction: direction,
      current: this.sections[this.state.next],
      previous: this.sections[this.state.prev],
    })

    this.state.prev = this.state.next

    setTimeout(() => {
      this.state.animating = false
    }, 800)
  }

  scrollUp = (e) => {
    if (!this.state.locked || this.state.animating) return
    this.state.scroll = e.y
  }

  moveUp() {
    if (!store.sniff.isDevice || !this.state.locked || this.state.animating)
      return

    const { scroll } = this.state

    if (scroll === 0 && this.touchDir == 'up') {
      this.state.locked = false

      setTimeout(() => {
        this.wheel.turnOn()
      }, 800)

      Emitter.emit('flip', {
        index: this.state.next,
        prev: this.state.prev + 1,
        direction: this.touchDir,
      })
    }
  }

  on() {
    this.wheel = new WheelIndicator({
      elem: store.page.el,
      callback: (e) => this.onScroll(e),
    })

    window.addEventListener('touchmove', this.mouseMove, { passive: true })
    window.addEventListener('touchstart', this.mouseDown, { passive: true })
    window.addEventListener('touchend', this.mouseUp, { passive: true })
    Emitter.on('scroll', this.scrollUp)
  }

  off() {
    this.wheel.destroy()
    window.removeEventListener('touchmove', this.mouseMove, { passive: true })
    window.removeEventListener('touchstart', this.mouseDown, { passive: true })
    window.removeEventListener('touchend', this.mouseUp, { passive: true })
    Emitter.off('scroll', this.scrollUp)
  }

  destroy() {
    this.off()
    gsap.set(store.body, { clearProps: 'all' })
  }

  init() {
    this.on()
  }
}

import { qs, qsa } from '../utils'
import gsap from 'gsap'

export function ContactIn() {
  const hero = qs('.heroContact')

  if (hero) {
    const lines = qsa('.line-0', hero)
    const p = qsa('p', hero)
    const a = qsa('a', hero)

    gsap.set(hero, { autoAlpha: 1 })

    gsap.from('.bg-block', { duration: 0.6, scaleY: 0, stagger: 0.05 })

    lines.forEach((line) => {
      const chars = qsa('.char-1', line)

      if (chars[0]) {
        gsap.from(chars, {
          duration: 0.8,
          y: '-101%',
          delay: 0.5,
          stagger: 0.05,
        })
      }
    })

    gsap.from(p, {
      duration: 0.6,
      autoAlpha: 0,
      y: 20,
      stagger: 0.1,
      delay: 0.8,
    })
    gsap.from(a, {
      duration: 0.6,
      autoAlpha: 0,
      y: 20,
      stagger: 0.1,
      delay: 0.8,
    })
  }
}

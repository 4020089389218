import { qs, qsa } from '../utils'
import gsap from 'gsap'

export function ElvaIn() {
  const hero = qs('.heroElva')

  if (hero) {
    const lines = qsa('h1 .line-0', hero)
    const chars = qsa('p .char-1', hero)

    gsap.set(hero, { autoAlpha: 1 })
    gsap.from('.bg-block', { duration: 0.6, scaleY: 0, stagger: 0.05 })

    lines.forEach((line) => {
      const chars = qsa('.char-1', line)

      if (chars[0]) {
        gsap.from(chars, {
          duration: 0.8,
          y: '-101%',
          delay: 0.5,
          stagger: 0.05,
        })
      }
    })

    if (chars[0])
      gsap.from(chars, {
        duration: 1,
        autoAlpha: 0,
        stagger: 0.02,
        delay: 0.5,
        ease: 'power3.inOut',
      })
  }
}

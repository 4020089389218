import store from '../store'
import {
  qs,
  bounds,
  updateSize,
  updateX,
  updateY,
  bindAll,
  lerp,
} from '../utils'
import { Emitter } from '../core'
import { Planes, TextGl } from '.'
import { Renderer, RenderTarget, Camera, Transform, Vec2 } from 'ogl'
import gsap from 'gsap'

export default class domOGL {
  constructor(obj = {}) {
    const textures = obj.textures
    const container = obj.container

    bindAll(this, 'run', 'resize', 'update')

    this.dom = {
      textures: textures,
      container: container,
      planes: [],
      total: 0,
    }

    this.state = {
      current: 0,
      scroll: 0,
      threshold: 100,
      isResizing: false,
      clock: 0,
      rollover: false,
    }

    this.settings = {
      x: 0,
      y: 0,
    }

    this.mouse = new Vec2(0, 0)
    this.time = 0
    this.init()
  }

  setup() {
    const { container } = this.dom
    const { page } = store
    const renderer = new Renderer({ dpr: 2, alpha: true, depth: false })
    const gl = renderer.gl
    const camera = new Camera(gl, { fov: 35 })
    const cameraTarget = new Camera(gl, { fov: 35 })

    const scene = new Transform()
    const targetScene = new Transform()
    const aspectRatio = page.vw / page.vh
    const target = new RenderTarget(gl, {
      width: page.vw,
      height: page.vh,
    })

    gl.clearColor(0.0, 0.0, 0.0, 0)
    target.gl.clearColor(0.0, 0.0, 0.0, 0)
    camera.position.set(0, 0, 5)
    camera.lookAt([0, 0, 0])
    camera.perspective({
      aspect: aspectRatio,
    })
    cameraTarget.position.set(0, 0, 5)
    cameraTarget.lookAt([0, 0, 0])
    cameraTarget.perspective({
      aspect: aspectRatio,
    })

    renderer.setSize(page.vw, page.vh)

    this.ogl = {
      renderer: renderer,
      target: target,
      gl: gl,
      camera: camera,
      cameraTarget: cameraTarget,
      texture: false,
      scene: scene,
      targetScene: targetScene,
    }

    gl.canvas.classList.add('gl-dom')
    container.appendChild(gl.canvas)
  }

  updatePlanes() {
    if (this.state.isResizing) return
    const { page, webgl } = store
    const { camera, targetScene } = this.ogl
    const { current } = this.state
    const aspectRatio = page.vw / page.vh
    const { images, slider, smiley, swirl } = webgl

    const imagesTotal = images.planes.length - 1
    const sliderTotal = slider.planes.length - 1
    const swirlTotal = swirl.planes.length - 1
    const smileyTotal = smiley.planes.length - 1

    // Slider Planes
    for (let i = 0; i <= sliderTotal; i++) {
      const el = slider.planes[0]
      const plane = slider.planes[i]
      const rect = bounds(el.el)
      const rectTexture = el.rect
      const scale = updateSize(rect, camera, aspectRatio)
      const posX = updateX(rectTexture.left, scale)
      const posY = updateY(rectTexture.top, scale, -current)
      const { size } = scale

      plane.scale = scale
      plane.plane.mesh.scale.set(size.x, size.y, 1)
      plane.plane.mesh.position.set(posX.x, posY.y, 1)
    }

    // Swirl Plane
    for (let i = 0; i <= swirlTotal; i++) {
      const plane = swirl.planes[i]
      const rectTexture = bounds(plane.el.parentNode)
      const scale = updateSize(rectTexture, camera, aspectRatio)
      const { size } = scale

      const data = plane.data.updateTexture()

      plane.scale = scale
      plane.rect = rectTexture
      plane.plane.mesh.scale.set(size.x, size.y, 1)

      targetScene.children = []
      data.textMesh.setParent(targetScene)
    }

    for (let i = 0; i <= smileyTotal; i++) {
      const plane = smiley.planes[i]
      const rectTexture = bounds(plane.el)
      const scale = updateSize(rectTexture, camera, aspectRatio)
      const { size } = scale

      plane.scale = scale
      plane.rect = rectTexture
      plane.plane.mesh.scale.set(size.x, size.y, 1)
    }

    // Image Planes
    for (let i = 0; i <= imagesTotal; i++) {
      const plane = images.planes[i]
      const rectTexture = bounds(plane.el)
      const scale = updateSize(rectTexture, camera, aspectRatio)
      const { size } = scale

      plane.scale = scale
      plane.rect = rectTexture
      plane.plane.mesh.scale.set(size.x, size.y, 1)
    }
  }

  getPlanes() {
    const { textures } = this.dom
    let cache

    if (!textures[0]) return

    this.dom.total = textures.length - 1

    textures.forEach((texture, i) => {
      const dataset = texture.dataset.plane

      if (dataset == 'slider') {
        cache = this.getPlane(texture, dataset)
        store.webgl.slider.planes.push(cache)
      }

      if (dataset == 'img') {
        cache = this.getPlane(texture, dataset)
        store.webgl.images.planes.push(cache)
      }

      if (dataset == 'smiley') {
        cache = this.getPlane(texture, dataset)
        store.webgl.smiley.planes.push(cache)
      }

      if (dataset == 'swirl') {
        cache = this.getSwirlText(texture, i)
        store.webgl.swirl.planes.push(cache)
      }

      this.dom.planes.push(cache)
    })
  }

  getPlane(texture, template) {
    const { page } = store
    const { gl, camera, scene } = this.ogl
    const { current } = this.state
    const aspectRatio = page.vw / page.vh
    const cache = []
    const rectTexture = bounds(texture)
    const scale = updateSize(rectTexture, camera, aspectRatio)
    const posX = updateX(rectTexture.left, scale)
    const posY = updateY(rectTexture.top, scale, -current)
    const img = texture.nextSibling
    const { size } = scale

    const data = new Planes({
      gl: gl,
      texture: img.dataset.src,
      template: template,
    })

    cache.el = texture
    cache.rect = rectTexture
    cache.plane = data.plane
    cache.scale = scale
    cache.top = posY.y
    cache.plane.mesh.scale.set(size.x, size.y, 1)
    cache.plane.mesh.position.set(posX.x, posY.y, 1)
    cache.plane.mesh.setParent(scene)
    cache.speed = 1 // Need to update if parallax
    cache.plane.program.uniforms.uSize.value.x = size.x
    cache.plane.program.uniforms.uSize.value.y = size.y
    cache.plane.program.uniforms.uPlaneSizes.value.x = size.x
    cache.plane.program.uniforms.uPlaneSizes.value.y = size.y
    cache.template = template

    return cache
  }

  getSwirlText(texture) {
    const { page } = store
    const { gl, camera, scene, renderer, target, targetScene } = this.ogl
    const { current } = this.state
    const aspectRatio = page.vw / page.vh

    const cache = []
    const rectTexture = bounds(texture)
    const scale = updateSize(rectTexture, camera, aspectRatio)
    const posX = updateX(rectTexture.left, scale)
    const posY = updateY(rectTexture.top, scale, -current)
    const { size } = scale
    const parent = texture.parentNode
    const text = qs('h2', parent).dataset.txt

    const data = new TextGl({
      gl: gl,
      renderer: renderer,
      target: target,
      text: text,
    })

    cache.el = texture
    cache.rect = rectTexture
    cache.data = data
    cache.plane = data.plane
    cache.scale = scale
    cache.top = posY.y
    cache.plane.mesh.scale.set(size.x, size.y, 1)
    cache.plane.mesh.position.set(posX.x, posY.y, 1)
    cache.plane.mesh.setParent(scene)

    setTimeout(() => {
      cache.plane.textMesh.setParent(targetScene)
    }, 1000)

    cache.speed = 1 // Need to update if parallax

    return cache
  }

  movePlanes() {
    if (this.state.isResizing) return

    const { planes } = this.dom
    const { current } = this.state
    const { webgl } = store
    const { images, slider, smiley, swirl } = webgl

    if (!planes[0]) return

    const imagesTotal = images.planes.length - 1
    const sliderTotal = slider.planes.length - 1
    const smileyTotal = smiley.planes.length - 1
    const swirlTotal = swirl.planes.length - 1

    // Image Planes
    for (let i = 0; i <= imagesTotal; i++) {
      const { plane, rect, scale } = images.planes[i]
      if (!plane.mesh) return

      const positionY = updateY(rect.top, scale, -current)
      plane.mesh.position.y = -positionY.y
    }

    // Smiley Plane
    for (let i = 0; i <= smileyTotal; i++) {
      const { plane, rect, scale } = smiley.planes[i]
      const { program } = plane
      if (!plane.mesh) return

      program.uniforms.uPosY.value = Math.abs(smiley.gooey)

      const elY = -current + smiley.posY

      const positionY = updateY(rect.top, scale, -current + smiley.posY)
      plane.mesh.position.y = -positionY.y
    }

    // Swirl Plane
    for (let i = 0; i <= swirlTotal; i++) {
      const { plane, rect, scale } = swirl.planes[i]
      const { program } = plane
      if (!plane.mesh) return

      if (program) {
        program.uniforms.uMouse.value = this.mouse
      }

      const positionY = updateY(rect.top, scale, -current + swirl.posY)
      plane.mesh.position.y = -positionY.y
    }

    // Slider Planes
    for (let i = 0; i <= sliderTotal; i++) {
      const { plane, scale } = slider.planes[i]
      const { position, program } = plane

      if (position) {
        position.render()
        position.passes[0].uniforms.uTime.value = this.time
        program.uniforms.uTime.value = this.time
        program.uniforms.positionTexture.value = position.uniform.value
      }

      plane.mesh.position.y = -updateY(
        webgl.slider.planes[i].posY,
        scale,
        -current,
      ).y

      plane.mesh.rotation.z = -webgl.slider.planes[i].posZ * 0.018
    }
  }

  run = (e) => {
    const { renderer, camera, scene, cameraTarget, targetScene, target } =
      this.ogl
    const { x, y } = this.settings
    const { page } = store
    const mouse = e.mouse
    this.time += 1 / 60
    this.state.current = e.current
    this.state.scroll = e.current
    this.state.diff = e.diff
    this.settings.x = lerp(x, mouse.x, 0.05)
    this.settings.y = lerp(y, mouse.y, 0.05)
    let posX,
      posY = 0

    if (this.state.isResizing) return

    this.movePlanes()

    if (this.state.rollover) {
      posX = (x / page.vw) * 2 - 1
      posY = -(y / page.vh) * 2 + 1
    } else {
      posX = 0
      posY = 0
    }

    gsap.to(this.mouse, 0.8, {
      x: posX,
      y: posY,
    })

    renderer.render({ scene: targetScene, camera: cameraTarget, target })
    renderer.render({ scene, camera })
  }

  on() {
    Emitter.on('tick', this.run)
    Emitter.on('resize', this.resize)
    Emitter.on('smooth:resize', this.update)

    if (store.webgl.swirl.planes[0]) {
      store.webgl.swirl.planes.forEach((plane) => {
        plane.el.addEventListener(
          'mouseenter',
          () => (this.state.rollover = true),
        )
        plane.el.addEventListener(
          'mouseleave',
          () => (this.state.rollover = false),
        )
      })
    }
  }

  off() {
    Emitter.off('tick', this.run)
    Emitter.off('resize', this.resize)
    Emitter.off('smooth:resize', this.update)

    if (store.webgl.swirl.planes[0]) {
      store.webgl.swirl.planes.forEach((plane) => {
        plane.el.removeEventListener(
          'mouseenter',
          () => (this.state.rollover = true),
        )
        plane.el.removeEventListener(
          'mouseleave',
          () => (this.state.rollover = false),
        )
      })
    }
  }

  update() {
    this.updatePlanes()
  }

  resize() {
    const { page } = store
    this.state.isResizing = true
    const aspectRatio = page.vw / page.vh

    this.ogl.camera.perspective({
      aspect: aspectRatio,
    })

    this.ogl.cameraTarget.perspective({
      aspect: aspectRatio,
    })

    this.ogl.target.setSize(page.vw, page.vh)
    this.ogl.renderer.setSize(page.vw, page.vh)

    this.state.isResizing = false
  }

  destroy() {
    const { gl } = this.ogl
    this.off()

    gl.clear(gl.COLOR_BUFFER_BIT)
    this.dom = null
    this.state = null
    this.ogl = null
    store.webgl.slider.planes = []
    store.webgl.images.planes = []
    store.webgl.swirl.planes = []
    store.webgl.smiley.planes = []
  }

  init() {
    this.setup()
    this.getPlanes()
    this.on()
  }
}

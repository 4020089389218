import store from '../store'
import { Emitter } from '../core/'
import { bounds } from '../utils'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import { gsap } from 'gsap'

export default class Sticky {
  constructor(obj) {
    this.sticky = obj.el
    this.el = this.sticky.parentNode
    this.state = {
      top: 0,
      bottom: 0,
      max: 0,
      min: 0,
      resize: false,
      animate: false,
      sticked: false,
      updated: false,
    }

    this.resize()
    this.onScrollTrigger()
  }

  onScrollTrigger() {
    const { totalH, height } = this.state
    const { sniff } = store

    if (sniff.browser.isDevice) {
      ScrollTrigger.create({
        trigger: this.sticky.parentNode,
        start: 'top top',
        end: 'bottom bottom',
        scrub: true,
        onEnter: () => {
          gsap.set(this.sticky, { top: 0 })
          this.state.sticked = true
          this.sticky.classList.add('-sticky')
        },
        onEnterBack: () => {
          gsap.set(this.sticky, { top: 0 })
          this.state.sticked = true
          this.sticky.classList.add('-sticky')
        },
        onLeaveBack: () => {
          this.state.sticked = false
          this.sticky.classList.remove('-sticky')
        },
        onLeave: () => {
          this.state.sticked = false
          this.sticky.classList.remove('-sticky')
          gsap.set(this.sticky, { top: this.state.totalH - this.state.height })
        },
      })
    } else {
      ScrollTrigger.create({
        trigger: this.sticky.parentNode,
        start: 'top top',
        end: 'bottom bottom',
        scrub: true,

        onUpdate: (self) => {
          const posY = `${
            self.progress * (this.state.totalH - this.state.height)
          }`

          if (this.sticky.classList.contains('smiley-content')) {
            store.webgl.smiley.posY = parseFloat(posY)
          }

          if (this.sticky.classList.contains('swirlBlock-content')) {
            store.webgl.swirl.posY = parseFloat(posY)
          }

          gsap.set(this.sticky, { y: posY })
        },
      })
    }
  }

  on() {
    const { sniff } = store

    if (sniff.browser.isDevice) {
      Emitter.on('resize', this.resize)
    } else {
      //Emitter.on('resize', this.resize)
      Emitter.on('smooth:resize', this.resize)
    }
  }

  off() {
    const { sniff } = store

    if (sniff.browser.isDevice) {
      Emitter.on('resize', this.resize)
    } else {
      // Emitter.off('resize', this.resize)
      Emitter.off('smooth:resize', this.resize)
    }
  }

  destroy() {
    this.off()
    //this.el = null
    //this.state = null
    store.webgl.smiley.posY = 0
    store.webgl.swirl.posY = 0
  }

  resize = () => {
    const el = this.sticky.parentNode
    const rect2 = bounds(this.sticky)
    const rect3 = bounds(el)
    const height = rect2.height
    const totalH = rect3.height

    gsap.set(this.sticky, { clearProps: 'all' })

    this.state.height = height
    this.state.totalH = totalH
  }
}
